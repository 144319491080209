import * as React from 'react';

export default class ErrorBoundary extends React.Component {

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        return this.props.children;
    }

}