import * as React from 'react';
import * as PropTypes from 'prop-types';
import {AbstractStatelessPureComponent, AjaxLoaderInjectedProps, AuthUtils, connector} from "lumen-react-javascript";
import {byeRequest, whoRequestLoaderChunk} from "../../type-definitions/endpoints";
import Navbar from "react-bootstrap/Navbar";
import * as config from "config";

interface Props extends AjaxLoaderInjectedProps {
}

export class AuthenticatedUserContextLoader extends AbstractStatelessPureComponent<Props> {

    static childContextTypes = {
        reload: PropTypes.func,
        build: PropTypes.string,
    };

    getChildContext() {
        return ({
            reload: () => this.props.refresh(),
            // build: this.props.versionHealthGeo.build,
        });
    }

    render() {
        return (
            <React.Fragment>
                <Navbar bg="light">
                    <Navbar.Brand href="/">Airbnb Reports</Navbar.Brand>&nbsp;&nbsp;&nbsp;
                    {!AuthUtils.isLoggedIn() ? (
                        <Navbar.Brand
                            href={`${config.backendUrl}/login?provider=google&redirect=${window.location.href}`}>Login</Navbar.Brand>
                    ) : (
                        <Navbar.Brand><span className="cursor-pointer" onClick={e => {
                            e.preventDefault();
                            byeRequest().then(() => window.location.reload());
                        }}>Logout</span></Navbar.Brand>
                    )}
                </Navbar>
                {AuthUtils.isLoggedIn() ? this.props.children : "You have no access here"}
            </React.Fragment>
        );
    }

}

export default connector(AuthenticatedUserContextLoader, {
        ajaxLoaderOptions: {
            loader: (
                <div key="loader-spinner"
                     className="position-fixed dim-r100 flex-horizontal justify-content-center align-items-center">
                    Loading...
                </div>
            ),
            promises: {
                ...whoRequestLoaderChunk((props, _) => _()
                    .then((authResponse: any) => {
                        AuthUtils.login(authResponse.accessToken, authResponse);
                        return authResponse;
                    })
                    .catch(() => {
                        AuthUtils.logout();
                        return null
                    }),
                ),
                // ...versionHealthGeoRequestLoaderChunk((props, _) => _()),
            }
        }
    },
);