import * as React from "react";
import {AbstractComponent, connector, Empty} from "lumen-react-javascript";
import {
    payoutReportShowByHashRequestLoaderChunk,
    PayoutReportShowByHashRequestPropsMapper,
    ReportShowByHashRequestPropsMapper
} from "../../../type-definitions/endpoints";
import {RouteComponentProps} from "react-router";
import {HorizontalBarSeries, HorizontalGridLines, VerticalGridLines, XAxis, XYPlot, YAxis} from 'react-vis';
import {renderEuros} from "../../../utils";
import Jumbotron from "react-bootstrap/Jumbotron";
import {monthIndex} from "../../../const";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Table from "react-bootstrap/Table";
import {Button} from "react-bootstrap";
import * as config from "config";

interface State {
}

interface Props extends PayoutReportShowByHashRequestPropsMapper, RouteComponentProps<{ reportHash: string }>, ReportShowByHashRequestPropsMapper {
}

export class PayoutReportRoute extends AbstractComponent<Props, State> {

    state = {};

    render() {
        const {month, year, notes, payouts} = this.props.payoutReportShowByHash;
        return (
            <div>
                <Jumbotron>
                    <strong><FontAwesomeIcon
                        icon="money-bill-alt"/>&nbsp;&nbsp;&nbsp;Payout {monthIndex.find(m => m.index === month).month} {year}
                    </strong><br/>
                    <strong><FontAwesomeIcon icon="address-book"/>&nbsp;&nbsp;&nbsp;Notes</strong><br/>
                    <p>{notes}</p>
                </Jumbotron>

                <div className="flex-vertical">
                    <div className="flex-horizontal">
                        <div className="p pl-x0">
                            <Button
                                href={`${config.backendUrl}/export/${this.props.match.params.reportHash}?payout_only=1`}>Export
                                (per payout)</Button>
                        </div>
                        <div className="p">
                            <Button
                                href={`${config.backendUrl}/export/${this.props.match.params.reportHash}?entries_only=1`}>Export
                                (per booking)</Button>
                        </div>
                        <div className="p">
                            <Button href={`${config.backendUrl}/export/${this.props.match.params.reportHash}`}>Export
                                (full)</Button>
                        </div>
                    </div>
                    <Table bordered size="sm" responsive="sm">
                        <thead>
                        <tr>
                            <td>Date</td>
                            <td>Payout</td>
                            <td>AirBnb fee (24%)</td>
                            <td>Cleaning fee</td>
                            <td>Rent</td>
                        </tr>
                        </thead>
                        <tbody>
                        {payouts.map((p, index) => {
                                let hostFee = p.entries.reduce((acc, e) => acc + e.host_fee, 0);
                                let cleaningFee = p.entries.reduce((acc, e) => acc + e.cleaning_fee, 0);
                                let className = index % 2 == 0 ? "bg-light" : ""
                                return (
                                    <Empty>
                                        <tr key={"a" + p.id} className={className}>
                                            <td>{p.date}</td>
                                            <td>{renderEuros(p.payout)}</td>
                                            <td>{renderEuros(hostFee)}</td>
                                            <td>{renderEuros(cleaningFee)}</td>
                                            <td>{renderEuros(p.payout + hostFee - cleaningFee)}</td>
                                        </tr>
                                        {p.entries.map(e => (
                                            <tr className={"small " + className}>
                                                <td>&nbsp;</td>
                                                <td>{renderEuros(e.amount)}</td>
                                                <td>{renderEuros(e.host_fee)}</td>
                                                <td>{renderEuros(e.cleaning_fee)}</td>
                                                <td>{renderEuros(e.amount + e.host_fee - e.cleaning_fee)}</td>
                                            </tr>
                                        ))}
                                    </Empty>
                                );
                            }
                        )}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td><strong>Total</strong></td>
                            <td><strong>{renderEuros(payouts.reduce((acc, inc) => acc + inc.payout, 0))}</strong></td>
                            <td>
                                <strong>{renderEuros(payouts.reduce((acc, inc) => acc + inc.entries.reduce((acc, e) => acc + e.host_fee, 0), 0))}</strong>
                            </td>
                            <td>
                                <strong>{renderEuros(payouts.reduce((acc, inc) => acc + inc.entries.reduce((acc, e) => acc + e.cleaning_fee, 0), 0))}</strong>
                            </td>
                            <td>
                                <strong>{renderEuros(payouts.reduce((acc, inc) => acc + inc.payout + inc.entries.reduce((acc, e) => acc + e.host_fee, 0) - inc.entries.reduce((acc, e) => acc + e.cleaning_fee, 0), 0))}</strong>
                            </td>
                        </tr>
                        </tfoot>
                    </Table>
                </div>
            </div>
        );
    }

}

export default connector(PayoutReportRoute, {
    ajaxLoaderOptions: {
        promises: {
            ...payoutReportShowByHashRequestLoaderChunk((props, _) => _(props.match.params.reportHash))
        }
    }
});
