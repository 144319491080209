import * as React from "react";
import {AbstractComponent, connector} from "lumen-react-javascript";
import {
    reportShowByHashRequestLoaderChunk,
    ReportShowByHashRequestPropsMapper
} from "../../../type-definitions/endpoints";
import {RouteComponentProps} from "react-router";
import {HorizontalBarSeries, HorizontalGridLines, VerticalGridLines, XAxis, XYPlot, YAxis} from 'react-vis';
import * as moment from 'moment';
import Chart from 'react-google-charts';
import {renderEuros} from "../../../utils";
import Jumbotron from "react-bootstrap/Jumbotron";
import {monthIndex} from "../../../const";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

interface State {
}

interface Props extends RouteComponentProps<{ reportHash: string }>, ReportShowByHashRequestPropsMapper {
}

export class ReportRoute extends AbstractComponent<Props, State> {

    state = {};

    render() {
        const {reportShowByHash: {payout_date, month, year, notes, property: {address}, entries}} = this.props;
        const timelineData = entries.map((e, index) => [
            e.guest,
            moment(e.date).toDate(),
            moment(e.date).add(e.nights, 'days').toDate()
        ]);
        const tableData = entries.map((e, index) => {
            let prefix = e.paid_out ? "" : "<span style='opacity: 0.25'>";
            let postfix = e.paid_out ? "" : "</span>";
            return [
                e.guest,
                moment(e.date).format("YYYY-MM-DD"),
                e.nights + ((e.nights != e.effective_nights) ? ` (${e.effective_nights})` : ""),
                renderEuros(e.payout_bruto / e.nights),
                renderEuros(e.payout_bruto),
                renderEuros(e.payout_netto),
            ].map(v => `${prefix}${v}${postfix}`);
        });

        const period = moment();
        period.year(year);
        period.month(month - 1);
        const startOfMonth = moment.min([moment(timelineData[0][2]), period.clone().startOf('month')]).clone();
        const endOfMonth = moment.max([period.clone().endOf('month'), moment(timelineData[timelineData.length - 1][2])]).clone();
        let daysInMonth = period.clone().endOf('month').add( 1, "day").diff(period.clone().startOf('month'), 'days');

        let nights = entries.map(e => e.nights).reduce((acc, e) => acc + e, 0);
        let effectiveNights = entries.map(e => e.effective_nights).reduce((acc, e) => acc + e, 0);
        let bruto = entries.reduce((acc, e) => acc + e.payout_bruto, 0);
        let netto = entries.reduce((acc, e) => acc + e.payout_netto, 0);
        let actualBruto = entries.filter(e => e.paid_out).reduce((acc, e) => acc + e.payout_bruto, 0);
        let actualNetto = entries.filter(e => e.paid_out).reduce((acc, e) => acc + e.payout_netto, 0);
        let effectiveBruto = entries.reduce((acc, e) => acc + (e.payout_bruto / e.nights) * e.effective_nights, 0);
        let effectiveBrutoPricePerNight = effectiveBruto / effectiveNights;
        let effectiveNightsRendered = nights + (nights != effectiveNights ? ` (${effectiveNights})` : "");
        let nightsPerBooking = entries.reduce((acc, e) => {
            let index = acc.findIndex(v => v[0] == e.nights);
            if (index >= 0) {
                acc[index][1] = acc[index][1] + 1;
            } else {
                acc.push([e.nights, 1]);
            }
            return acc;
        }, [["Nights per booking", "Occurence"]] as any).map(e => {
            e[0] = `${e[0]} nights`
            return e;
        });
        return (
            <div>
                <Jumbotron>
                    <strong><FontAwesomeIcon
                        icon="home"/>&nbsp;&nbsp;&nbsp;{address} - {monthIndex.find(m => m.index === month).month} {year}
                    </strong><br/>
                    <strong><FontAwesomeIcon icon="money-bill-alt"/>&nbsp;&nbsp;&nbsp;Payout date: {payout_date}
                    </strong><br/>
                    <strong><FontAwesomeIcon icon="address-book"/>&nbsp;&nbsp;&nbsp;Notes</strong><br/>
                    <p>{notes}</p>
                </Jumbotron>

                <div className="mv flex-horizontal">
                    <div className="mr flex-1 flex-vertical">
                        <div className="mb">
                            <Chart
                                width="100%"
                                chartType="Table"
                                loader={<div>Loading Table</div>}
                                data={[
                                    [
                                        {type: 'string', label: 'Guest'},
                                        {type: 'string', label: 'Check-in'},
                                        {type: 'string', label: 'Nights'},
                                        {type: 'string', label: 'Price per night'},
                                        {type: 'string', label: 'Price'},
                                        {type: 'string', label: 'Payout'},
                                    ],
                                    ...tableData,
                                    [
                                        "<strong>" + "TOTAL" + "</strong>",
                                        "",
                                        "<strong>" + effectiveNightsRendered + "</strong>",
                                        "<strong>" + renderEuros(effectiveBrutoPricePerNight) + " (AVG)</strong>",
                                        "<strong>" + renderEuros(actualBruto) + "</strong>",
                                        "<strong>" + renderEuros(actualNetto) + "</strong>",
                                    ]
                                ]}
                                options={{
                                    allowHtml: true,
                                }}
                            />
                        </div>
                        <div className="mv">
                            <Chart
                                width="100%"
                                chartType="Table"
                                loader={<div>Loading Table</div>}
                                data={[
                                    [
                                        {type: 'string', label: "Metric"},
                                        {type: 'string', label: "Value"},
                                    ],
                                    [
                                        "<span>" + "Total" + "</span>",
                                        "<strong>" + renderEuros(actualBruto) + "</strong>",
                                    ],
                                    [
                                        "<span>" + "Nordic Heart fee" + "</span>",
                                        "<strong>" + renderEuros(actualBruto - actualNetto) + "</strong>",
                                    ],
                                    [
                                        "<span>" + "Your earnings" + "</span>",
                                        "<strong>" + renderEuros(actualNetto) + "</strong>",
                                    ],
                                    [
                                        "",
                                        "",
                                    ],
                                    [
                                        "<span>" + "Number of nights" + "</span>",
                                        "<strong>" + effectiveNightsRendered + "</strong>",
                                    ],
                                    [
                                        "<span>" + "Average price per night" + "</span>",
                                        "<strong>" + renderEuros(effectiveBrutoPricePerNight) + "</strong>",
                                    ],
                                    [
                                        "<span>" + "Occupancy rate" + "</span>",
                                        "<strong>" + ((effectiveNights / daysInMonth) * 100).toFixed(2) + "%</strong>",
                                    ],
                                    [
                                        "<span>" + "Average stay duration" + "</span>",
                                        "<strong>" + (nights / entries.length).toFixed(2) + "</strong>",
                                    ],
                                ]}
                                options={{
                                    allowHtml: true,
                                }}
                            />
                        </div>
                    </div>
                    <div className="ml flex-2">
                        <div className="mb">
                            <Chart
                                width="100%"
                                height={`${(timelineData.length + 1) * 42.5}px`}
                                chartType="Timeline"
                                loader={<div>Loading Chart</div>}
                                data={[
                                    [
                                        {type: 'string', id: 'Name'},
                                        {type: 'date', id: 'Start'},
                                        {type: 'date', id: 'End'},
                                    ],
                                    ...timelineData,
                                ]}
                                options={{
                                    hAxis: {
                                        minValue: startOfMonth.toDate(),
                                        maxValue: endOfMonth.toDate(),
                                    }
                                }}
                            />
                        </div>
                        <div className="mv">
                            <div className="flex-horizontal">
                                <div className="flex-1">
                                    <Chart
                                        width="100%"
                                        height="300px"
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ['', 'Nights'],
                                            ["Booked", Math.max(effectiveNights, 0)],
                                            ["Unbooked", Math.max(daysInMonth - effectiveNights, 0)]
                                        ]}
                                        options={{
                                            title: 'Occupancy rate',
                                            legend: 'none',
                                            pieSliceText: 'label',
                                            slices: {
                                                0: {color: 'green'},
                                                1: {color: 'salmon'},
                                            },
                                        }}
                                    />
                                </div>
                                <div className="flex-1">
                                    <Chart
                                        width="100%"
                                        height="300px"
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={nightsPerBooking}
                                        options={{
                                            title: 'Nights per booking',
                                            legend: 'none',
                                            pieSliceText: 'label',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connector(ReportRoute, {
    ajaxLoaderOptions: {
        promises: {
            ...reportShowByHashRequestLoaderChunk((props, _) => _(props.match.params.reportHash, {with: ['property']}))
        }
    }
});
