import * as React from "react";
import {AbstractComponent, AjaxLoaderInjectedProps, connector} from "lumen-react-javascript";
import {
    createFormData,
    FilesInput,
    importCsvRequest,
    listCsvPropertiesRequest,
    propertyEntryDestroyRequest,
    propertyEntryStoreRequest,
    propertyEntryUpdateRequest,
    propertyIndexRequestLoaderChunk,
    PropertyIndexRequestPropsMapper,
    propertyShowRequestLoaderChunk,
    PropertyShowRequestPropsMapper,
    reportDestroyRequest,
    reportStoreRequest
} from "../../../type-definitions/endpoints";
import {RouteComponentProps} from "react-router";
import Table from "react-bootstrap/Table";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {Button, Jumbotron, Modal} from "react-bootstrap";
import Dropzone from 'react-dropzone'
import {parseEuros, renderEuros, renderMonthInput, renderYearInput} from "../../../utils";
import DatePicker from "react-date-picker";
import * as moment from "moment";

interface State {
    files: FilesInput
    id: string
    property_id: string
    date: Date
    payout_date: Date
    type: string
    code: string
    nights: string
    guest: string
    details: string
    reference: string
    amount: string
    host_fee: string
    cleaning_fee: string
    createReport: boolean
    notes: string
    report_payout_date: Date
    editId: number
    mappings: object
    request: any
}

interface Props extends PropertyIndexRequestPropsMapper, PropertyShowRequestPropsMapper, AjaxLoaderInjectedProps, RouteComponentProps<{ propertyId: string, importMonth: string, importYear: string }> {
}

export class PropertyRoute extends AbstractComponent<Props, State> {

    state = {
        files: null,
        id: "",
        property_id: "",
        date: new Date(),
        payout_date: new Date(),
        type: "",
        code: "",
        nights: "",
        guest: "",
        details: "",
        reference: "",
        amount: "",
        host_fee: "",
        cleaning_fee: "",
        createReport: false,
        notes: "",
        report_payout_date: new Date(),
        editId: -1,
        mappings: null,
        request: null,
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Jumbotron>
                            <h1>{this.props.propertyShow.address}</h1>
                            <h2 className="p">Import new data</h2>
                            <div className="flex-horizontal">
                                <div className="flex-1">
                                    <div className="p">
                                        {renderMonthInput(this.props.match.params.importMonth, importMonth => {
                                            this.history.push(`/property/${this.props.match.params.propertyId}/${importMonth}/${this.props.match.params.importYear}`);
                                            this.props.refresh();
                                        }, true)}
                                    </div>
                                    <div className="p">
                                        {renderYearInput(this.props.match.params.importYear, importYear => {
                                            this.history.push(`/property/${this.props.match.params.propertyId}/${this.props.match.params.importMonth}/${importYear}`);
                                            this.props.refresh();
                                        }, true)}
                                    </div>
                                </div>
                                <div className="flex-1">
                                    &nbsp;
                                </div>
                                <div className="flex-1">
                                    <div className="flex-vertical">
                                        <div
                                            className="p border bg-white flex-horizontal justify-content-center align-items-center">
                                            <Dropzone onDrop={files => this.setState({files})}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <span>Drop CSV here</span>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <div className="pt flex-horizontal justify-content-between">
                                            <div className="flex-horizontal">
                                                <div
                                                    className="cursor-pointer display-inline-block"
                                                    onClick={() => confirm("Are you sure this CSV is reservations?") && listCsvPropertiesRequest(
                                                        {csv: this.state.files}
                                                    )
                                                        .then((listings: any) => {
                                                            let mappings = {};
                                                            for (let i = 0; i < listings.length; i++) {
                                                                mappings[listings[i]] = null;
                                                            }
                                                            this.setState({
                                                                mappings,
                                                                request: () => importCsvRequest(createFormData({csv: this.state.files}, {mappings: JSON.stringify(this.state.mappings)}), {
                                                                    headers: {
                                                                        "Content-Type": 'multipart/form-data'
                                                                    }
                                                                }).then(() => this.props.refresh())
                                                            });
                                                        })}
                                                >
                                                    <Button>Import Reservations</Button>
                                                </div>
                                            </div>
                                            {/*<div className="flex-horizontal">*/}
                                            {/*<div*/}
                                            {/*className="cursor-pointer display-inline-block"*/}
                                            {/*onClick={() => confirm("Are you sure this CSV is payouts?") && listPayoutCsvPropertiesRequest(*/}
                                            {/*{csv: this.state.files}*/}
                                            {/*)*/}
                                            {/*.then((listings: any) => {*/}
                                            {/*let mappings = {};*/}
                                            {/*for (let i = 0; i < listings.length; i++) {*/}
                                            {/*mappings[listings[i]] = null;*/}
                                            {/*}*/}
                                            {/*this.setState({*/}
                                            {/*mappings,*/}
                                            {/*request: () => importPayoutCsvRequest(createFormData({csv: this.state.files}, {mappings: JSON.stringify(this.state.mappings)}), {*/}
                                            {/*headers: {*/}
                                            {/*"Content-Type": 'multipart/form-data'*/}
                                            {/*}*/}
                                            {/*}).then(() => this.props.refresh())*/}
                                            {/*});*/}
                                            {/*})}*/}
                                            {/*>*/}
                                            {/*<Button>Import Payouts</Button>*/}
                                            {/*</div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col xl={8}>
                        <Table striped bordered hover size="sm" responsive="sm">
                            <thead>
                            <tr>
                                <td>Id</td>
                                <td>Payout Date</td>
                                <td>Date</td>
                                <td>Type</td>
                                <td>Code</td>
                                <td>Nights</td>
                                <td>Guest</td>
                                <td>Amount</td>
                                <td>Host Fee</td>
                                <td>Cleaning Fee</td>
                                <td>Paid out</td>
                                <td>&nbsp;</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.propertyShow.entries.map(e => this.state.editId !== e.id ? (
                                <tr key={e.id}>
                                    <td>{e.id}</td>
                                    <td>{e.payout_date}</td>
                                    <td>{e.date}</td>
                                    <td>{e.type}</td>
                                    <td>{e.code}</td>
                                    <td>{e.nights}</td>
                                    <td>{e.guest}</td>
                                    <td>{renderEuros(e.amount)}</td>
                                    <td>{renderEuros(e.host_fee)}</td>
                                    <td>{renderEuros(e.cleaning_fee)}</td>
                                    <td>{e.payout_id ? "YES" : ""}</td>
                                    <td>
                                        <div className="flex-horizontal">
                                            <div onClick={() => {
                                                this.setState({
                                                    editId: e.id,
                                                    property_id: e.property_id.toString(),
                                                    payout_date: moment(e.payout_date, "YYYY-MM-DD").toDate(),
                                                    date: moment(e.date, "YYYY-MM-DD").toDate(),
                                                    type: e.type,
                                                    code: e.code,
                                                    nights: e.nights.toString(),
                                                    guest: e.guest,
                                                    details: e.details,
                                                    reference: e.reference,
                                                    amount: renderEuros(e.amount),
                                                    host_fee: renderEuros(e.host_fee),
                                                    cleaning_fee: renderEuros(e.cleaning_fee),
                                                })
                                            }}
                                                 className="cursor-pointer ph">
                                                <FontAwesomeIcon icon="edit"/>
                                            </div>
                                            <div
                                                onClick={() => confirm("Are you sure you want to delete") ? propertyEntryDestroyRequest(e.id).then(() => this.props.refresh()) : null}
                                                className="cursor-pointer ph">
                                                <FontAwesomeIcon icon="trash"/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                this.editRow(e.id)
                            ))}
                            </tbody>

                            <tfoot>
                            {this.state.editId < 0 ? this.editRow() : <tr>
                                <td colSpan={12}>&nbsp;</td>
                            </tr>}
                            </tfoot>
                        </Table>
                    </Col>
                    <Col xl={4}>
                        <Table striped bordered hover size="sm" responsive="sm">
                            <thead>
                            <tr>
                                <td>Id</td>
                                <td>Payout Date</td>
                                <td>&nbsp;</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.propertyShow.reports.map(r => (
                                <tr key={r.id}>
                                    <td>{r.id}</td>
                                    <td>{r.payout_date}</td>
                                    <td>
                                        <div className="flex-horizontal">
                                            <div
                                                onClick={() => window.open(`${window.location.origin}/report/${r.hash}`, '_blank')}
                                                className="cursor-pointer ph">
                                                <FontAwesomeIcon icon="link"/>
                                            </div>
                                            <div
                                                onClick={() => confirm("Are you sure you want to delete") ? reportDestroyRequest(r.id).then(() => this.props.refresh()) : null}
                                                className="cursor-pointer ph">
                                                <FontAwesomeIcon icon="trash"/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td className="text-right" colSpan={3}>
                                    <Button
                                        disabled={parseInt(this.props.match.params.importMonth) < 0 || parseInt(this.props.match.params.importYear) < 0}
                                        onClick={() => this.setState({createReport: true})}>Create Report</Button>
                                </td>
                            </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                <Modal show={this.state.createReport} onHide={() => this.setState({createReport: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="p">
                            <span>Notes</span><br/>
                            <Form.Control value={this.state.notes} as="textarea"
                                          onChange={e => this.setState({notes: e.target.value})}/>
                        </div>
                        <div className="p">
                            <span>Payout date</span><br/>
                            <DatePicker className="form-control" value={this.state.report_payout_date}
                                        onChange={report_payout_date => this.setState({report_payout_date: report_payout_date as Date})}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({createReport: false})}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            reportStoreRequest({
                                id: null,
                                property_id: parseInt(this.props.match.params.propertyId),
                                month: parseInt(this.props.match.params.importMonth),
                                year: parseInt(this.props.match.params.importYear),
                                notes: this.state.notes,
                                payout_date: moment(this.state.report_payout_date).format("YYYY-MM-DD")
                            }).then(() => {
                                this.props.refresh();
                                this.setState({createReport: false});
                            })
                        }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={!!this.state.mappings && !!this.state.request}
                    onHide={() => this.setState({mappings: null, request: null})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Set mappings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="p">
                            {this.renderMappings()}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({mappings: null, request: null})}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            this.state.request({mappings: this.state.mappings}).then(() => {
                                this.props.refresh();
                                this.setState({mappings: null, request: null});
                            })
                        }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }

    private renderMappings() {
        let mappingsElements = [];
        for (let key in this.state.mappings) {
            mappingsElements.push(
                <div key={key} className="flex-horizontal">
                    <div className="p">{key}</div>
                    <div className="p">
                        <select value={this.state.mappings[key]}
                                onChange={e => {
                                    let mappings = {...this.state.mappings};
                                    mappings[key] = e.target.value;
                                    this.setState({mappings});
                                }}>
                            <option>Select...</option>
                            {this.props.propertyIndex.map(p =>
                                <option key={p.id} value={p.id}>{p.address}</option>
                            )}
                        </select>
                    </div>
                </div>
            );
        }
        return mappingsElements;
    }

    private editRow(id = null) {
        let callback = () => {
            const payload = {
                id,
                property_id: parseInt(this.props.match.params.propertyId),
                payout_date: moment(this.state.payout_date).format("YYYY-MM-DD"),
                date: moment(this.state.date).format("YYYY-MM-DD"),
                type: this.state.type,
                code: this.state.code,
                nights: parseInt(this.state.nights),
                guest: this.state.guest,
                details: this.state.details,
                reference: this.state.reference,
                amount: parseEuros(this.state.amount),
                host_fee: parseEuros(this.state.host_fee),
                cleaning_fee: parseEuros(this.state.cleaning_fee),
                payout_id: null,
            };
            (id ? propertyEntryUpdateRequest(payload) : propertyEntryStoreRequest(payload)).then(() => {
                this.setState({
                    editId: -1,
                    property_id: "",
                    payout_date: new Date(),
                    date: new Date(),
                    type: "",
                    code: "",
                    nights: "",
                    guest: "",
                    details: "",
                    reference: "",
                    amount: "",
                    host_fee: "",
                    cleaning_fee: "",
                });
                this.props.refresh();
            })
        };
        return (
            <tr>
                <td>{id}</td>
                <td><DatePicker className="form-control" value={this.state.payout_date}
                                onChange={payout_date => this.setState({payout_date: payout_date as Date})}/></td>
                <td><DatePicker className="form-control" value={moment(this.state.date).toDate()}
                                onChange={date => this.setState({date: date as Date})}/></td>
                <td><Form.Control value={this.state.type} type="text" placeholder="type"
                                  onChange={e => this.setState({type: e.target.value})}/></td>
                <td><Form.Control value={this.state.code} type="text" placeholder="code"
                                  onChange={e => this.setState({code: e.target.value})}/></td>
                <td><Form.Control value={this.state.nights} type="number" placeholder="nights"
                                  onChange={e => this.setState({nights: e.target.value})}/></td>
                <td><Form.Control value={this.state.guest} type="text" placeholder="guest"
                                  onChange={e => this.setState({guest: e.target.value})}/></td>
                <td><Form.Control value={this.state.amount} type="text" placeholder="amount"
                                  onChange={e => this.setState({amount: e.target.value})}/></td>
                <td><Form.Control value={this.state.host_fee} type="text" placeholder="host_fee"
                                  onChange={e => this.setState({host_fee: e.target.value})}/></td>
                <td><Form.Control value={this.state.cleaning_fee} type="text" placeholder="cleaning_fee"
                                  onChange={e => this.setState({cleaning_fee: e.target.value})}/></td>
                <td>&nbsp;</td>
                <td>
                    {id ? (
                        <div className="flex-horizontal">
                            <div onClick={() => callback()} className="cursor-pointer ph">
                                <FontAwesomeIcon icon="edit"/>
                            </div>
                            <div onClick={() => this.setState({editId: -1})} className="cursor-pointer ph">
                                <FontAwesomeIcon icon="step-backward"/>
                            </div>
                        </div>
                    ) : (
                        <div onClick={() => callback()} className="cursor-pointer ph"><FontAwesomeIcon icon="plus"/>
                        </div>
                    )}
                </td>
            </tr>
        );
    }

}

export default connector(PropertyRoute, {
    ajaxLoaderOptions: {
        promises: {
            ...propertyIndexRequestLoaderChunk((props, _) => _()),
            ...propertyShowRequestLoaderChunk((props, _) => {
                let filter = [];
                if (parseInt(props.match.params.importMonth) > 0) {
                    filter.push(['entries.payout_month', 'eq', props.match.params.importMonth]);
                    filter.push(['reports.month', 'eq', props.match.params.importMonth]);
                }
                if (parseInt(props.match.params.importYear) > 0) {
                    filter.push(['entries.payout_year', 'eq', props.match.params.importYear]);
                    filter.push(['reports.year', 'eq', props.match.params.importYear]);
                }
                return _(parseInt(props.match.params.propertyId), {
                    with: ['entries', 'reports'],
                    filter
                })
            }),
        }
    }
});

// promises: {
// ...propertyIndexRequestLoaderChunk((props, _) => _()),
// ...propertyShowRequestLoaderChunk((props, _) => _(parseInt(props.match.params.propertyId),
//         {
//             queryString: {month: props.match.params.importMonth, year: props.match.params.importYear},
//         }
//     )),
// }