import React from 'react';
import Cookie from 'js-cookie';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import './app.scss';
import languages from './languages';
import {App, run} from 'lumen-react-javascript';
import ErrorBoundary from "./components/error-boundary";
import fontawesome from '@fortawesome/fontawesome';
import {faPlus, faEdit, faTrash, faStepBackward, faLink, faHome, faMoneyBillAlt, faAddressBook} from "@fortawesome/fontawesome-free-solid";

export function boot(Entry, bootOptions) {
	run(Entry, {
		App: bootOptions.App,
		ErrorBoundary,
		languages,
		localeCallback: Cookie.get('locale') || navigator.language.split('-')[0] || null,
		appName: bootOptions.appName || 'default',
		className: bootOptions.className,
		module,
		reducers: () => {
		},
		postBoot: bootOptions.postBoot,
		preRenderCallback: () => fontawesome.library.add(faPlus, faEdit, faTrash, faStepBackward, faLink, faHome, faMoneyBillAlt, faAddressBook),
	});
}