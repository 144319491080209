import axios, {AxiosResponse, AxiosError, AxiosInstance} from 'axios';
import {AxiosRequestConfiguration, ExtendedResponseData, getUrl, parseHeadersToApiResponseHeaders} from 'lumen-react-javascript';
import _ from 'lodash';
import { User, Property, PropertyEntry, Report, Payout, PayoutReport, Mappings } from './types';

const apiDateFields = [

];

export interface FilesInput {
	[key: string]: File | Array<File>;
}

export function convertApiFields<T>(object: T): T {
    if (_.isArray(object)) {
        let ret: any = [];
        let array: Array<any> = object as any;
        for (let i = 0; i < array.length; i++) {
            ret[i] = convertApiFields<T>(array[i]);
        }
        return ret as T;
    } else if (_.isObject(object) && !_.isNull(object)) {
        let ret: any = {};
        for (let key in object) {
            if (_.isNull(object[key])) {
                ret[key] = null;
            } else if (_.isArray(object[key])) {
                ret[key] = [];
                let array: Array<any> = object[key] as any;
                for (let i = 0; i < array.length; i++) {
                    ret[key][i] = convertApiFields<any>(array[i]);
                }
            } else if (_.isObject(object[key])) {
                ret[key] = convertApiFields<any>(object[key]);
            } else {
                ret[key] = convertApiField(key, object[key]);
            }
        }
        return ret as T;
    } else {
        return object;
    }
}

export function convertApiField(key: string, value: any): any {
	let ret = value;
	if (_.indexOf(apiDateFields, key) >= 0) {
		ret = new Date(value);
	}
	return ret;
}

export function createFormData(files, input?): FormData {
	let formData = new FormData();
	for (let key in files) {
		if (_.isArray(files[key])) {
			for (let i in files[key]) {
				formData.append(`${key}[${i}]`, files[key][i]);
			}
		} else {
			formData.append(`${key}`, files[key]);
		}
	}
	if (input) {
		for (let key in input) {
			formData.append(key, input[key]);
		}
	}
	return formData;
}

export function userIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<User>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<User>>((resolve, reject) => {
		instance.get<Array<User>>(
			getUrl(`/user`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<User>>) => {
			resolve(convertApiFields<Array<User>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<User>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<User>>>((resolve, reject) => {
		instance.get<Array<User>>(
			getUrl(`/user`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<User>>) => {
			resolve({
				data: convertApiFields<Array<User>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userStoreRequest(input: User, requestConfig?: AxiosRequestConfiguration): Promise<User> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<User>((resolve, reject) => {
		instance.put<User>(
			getUrl(`/user`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve(convertApiFields<User>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userStoreRequestExtended(input: User, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<User>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<User>>((resolve, reject) => {
		instance.put<User>(
			getUrl(`/user`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve({
				data: convertApiFields<User>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userShowRequest(userId: number, requestConfig?: AxiosRequestConfiguration): Promise<User> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<User>((resolve, reject) => {
		instance.get<User>(
			getUrl(`/user/${userId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve(convertApiFields<User>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userShowRequestExtended(userId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<User>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<User>>((resolve, reject) => {
		instance.get<User>(
			getUrl(`/user/${userId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve({
				data: convertApiFields<User>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userUpdateRequest(input: User, requestConfig?: AxiosRequestConfiguration): Promise<User> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<User>((resolve, reject) => {
		instance.post<User>(
			getUrl(`/user/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve(convertApiFields<User>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userUpdateRequestExtended(input: User, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<User>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<User>>((resolve, reject) => {
		instance.post<User>(
			getUrl(`/user/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve({
				data: convertApiFields<User>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userDestroyRequest(userId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/user/${userId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userDestroyRequestExtended(userId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/user/${userId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Property>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Property>>((resolve, reject) => {
		instance.get<Array<Property>>(
			getUrl(`/property`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Property>>) => {
			resolve(convertApiFields<Array<Property>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Property>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Property>>>((resolve, reject) => {
		instance.get<Array<Property>>(
			getUrl(`/property`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Property>>) => {
			resolve({
				data: convertApiFields<Array<Property>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyStoreRequest(input: Property, requestConfig?: AxiosRequestConfiguration): Promise<Property> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Property>((resolve, reject) => {
		instance.put<Property>(
			getUrl(`/property`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Property>) => {
			resolve(convertApiFields<Property>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyStoreRequestExtended(input: Property, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Property>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Property>>((resolve, reject) => {
		instance.put<Property>(
			getUrl(`/property`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Property>) => {
			resolve({
				data: convertApiFields<Property>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyShowRequest(propertyId: number, requestConfig?: AxiosRequestConfiguration): Promise<Property> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Property>((resolve, reject) => {
		instance.get<Property>(
			getUrl(`/property/${propertyId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Property>) => {
			resolve(convertApiFields<Property>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyShowRequestExtended(propertyId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Property>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Property>>((resolve, reject) => {
		instance.get<Property>(
			getUrl(`/property/${propertyId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Property>) => {
			resolve({
				data: convertApiFields<Property>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyUpdateRequest(input: Property, requestConfig?: AxiosRequestConfiguration): Promise<Property> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Property>((resolve, reject) => {
		instance.post<Property>(
			getUrl(`/property/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Property>) => {
			resolve(convertApiFields<Property>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyUpdateRequestExtended(input: Property, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Property>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Property>>((resolve, reject) => {
		instance.post<Property>(
			getUrl(`/property/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Property>) => {
			resolve({
				data: convertApiFields<Property>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyDestroyRequest(propertyId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/property/${propertyId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyDestroyRequestExtended(propertyId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/property/${propertyId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<PropertyEntry>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<PropertyEntry>>((resolve, reject) => {
		instance.get<Array<PropertyEntry>>(
			getUrl(`/propertyEntry`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<PropertyEntry>>) => {
			resolve(convertApiFields<Array<PropertyEntry>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<PropertyEntry>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<PropertyEntry>>>((resolve, reject) => {
		instance.get<Array<PropertyEntry>>(
			getUrl(`/propertyEntry`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<PropertyEntry>>) => {
			resolve({
				data: convertApiFields<Array<PropertyEntry>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryStoreRequest(input: PropertyEntry, requestConfig?: AxiosRequestConfiguration): Promise<PropertyEntry> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<PropertyEntry>((resolve, reject) => {
		instance.put<PropertyEntry>(
			getUrl(`/propertyEntry`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<PropertyEntry>) => {
			resolve(convertApiFields<PropertyEntry>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryStoreRequestExtended(input: PropertyEntry, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<PropertyEntry>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<PropertyEntry>>((resolve, reject) => {
		instance.put<PropertyEntry>(
			getUrl(`/propertyEntry`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<PropertyEntry>) => {
			resolve({
				data: convertApiFields<PropertyEntry>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryShowRequest(propertyEntryId: number, requestConfig?: AxiosRequestConfiguration): Promise<PropertyEntry> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<PropertyEntry>((resolve, reject) => {
		instance.get<PropertyEntry>(
			getUrl(`/propertyEntry/${propertyEntryId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<PropertyEntry>) => {
			resolve(convertApiFields<PropertyEntry>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryShowRequestExtended(propertyEntryId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<PropertyEntry>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<PropertyEntry>>((resolve, reject) => {
		instance.get<PropertyEntry>(
			getUrl(`/propertyEntry/${propertyEntryId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<PropertyEntry>) => {
			resolve({
				data: convertApiFields<PropertyEntry>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryUpdateRequest(input: PropertyEntry, requestConfig?: AxiosRequestConfiguration): Promise<PropertyEntry> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<PropertyEntry>((resolve, reject) => {
		instance.post<PropertyEntry>(
			getUrl(`/propertyEntry/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<PropertyEntry>) => {
			resolve(convertApiFields<PropertyEntry>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryUpdateRequestExtended(input: PropertyEntry, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<PropertyEntry>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<PropertyEntry>>((resolve, reject) => {
		instance.post<PropertyEntry>(
			getUrl(`/propertyEntry/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<PropertyEntry>) => {
			resolve({
				data: convertApiFields<PropertyEntry>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryDestroyRequest(propertyEntryId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/propertyEntry/${propertyEntryId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function propertyEntryDestroyRequestExtended(propertyEntryId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/propertyEntry/${propertyEntryId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Report>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Report>>((resolve, reject) => {
		instance.get<Array<Report>>(
			getUrl(`/report`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Report>>) => {
			resolve(convertApiFields<Array<Report>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Report>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Report>>>((resolve, reject) => {
		instance.get<Array<Report>>(
			getUrl(`/report`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Report>>) => {
			resolve({
				data: convertApiFields<Array<Report>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportStoreRequest(input: Report, requestConfig?: AxiosRequestConfiguration): Promise<Report> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Report>((resolve, reject) => {
		instance.put<Report>(
			getUrl(`/report`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Report>) => {
			resolve(convertApiFields<Report>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportStoreRequestExtended(input: Report, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Report>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Report>>((resolve, reject) => {
		instance.put<Report>(
			getUrl(`/report`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Report>) => {
			resolve({
				data: convertApiFields<Report>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportShowRequest(reportId: number, requestConfig?: AxiosRequestConfiguration): Promise<Report> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Report>((resolve, reject) => {
		instance.get<Report>(
			getUrl(`/report/${reportId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Report>) => {
			resolve(convertApiFields<Report>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportShowRequestExtended(reportId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Report>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Report>>((resolve, reject) => {
		instance.get<Report>(
			getUrl(`/report/${reportId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Report>) => {
			resolve({
				data: convertApiFields<Report>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportUpdateRequest(input: Report, requestConfig?: AxiosRequestConfiguration): Promise<Report> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Report>((resolve, reject) => {
		instance.post<Report>(
			getUrl(`/report/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Report>) => {
			resolve(convertApiFields<Report>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportUpdateRequestExtended(input: Report, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Report>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Report>>((resolve, reject) => {
		instance.post<Report>(
			getUrl(`/report/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Report>) => {
			resolve({
				data: convertApiFields<Report>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportDestroyRequest(reportId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/report/${reportId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportDestroyRequestExtended(reportId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/report/${reportId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Payout>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Payout>>((resolve, reject) => {
		instance.get<Array<Payout>>(
			getUrl(`/payout`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Payout>>) => {
			resolve(convertApiFields<Array<Payout>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Payout>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Payout>>>((resolve, reject) => {
		instance.get<Array<Payout>>(
			getUrl(`/payout`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Payout>>) => {
			resolve({
				data: convertApiFields<Array<Payout>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutStoreRequest(input: Payout, requestConfig?: AxiosRequestConfiguration): Promise<Payout> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Payout>((resolve, reject) => {
		instance.put<Payout>(
			getUrl(`/payout`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Payout>) => {
			resolve(convertApiFields<Payout>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutStoreRequestExtended(input: Payout, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Payout>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Payout>>((resolve, reject) => {
		instance.put<Payout>(
			getUrl(`/payout`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Payout>) => {
			resolve({
				data: convertApiFields<Payout>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutShowRequest(payoutId: number, requestConfig?: AxiosRequestConfiguration): Promise<Payout> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Payout>((resolve, reject) => {
		instance.get<Payout>(
			getUrl(`/payout/${payoutId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Payout>) => {
			resolve(convertApiFields<Payout>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutShowRequestExtended(payoutId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Payout>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Payout>>((resolve, reject) => {
		instance.get<Payout>(
			getUrl(`/payout/${payoutId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Payout>) => {
			resolve({
				data: convertApiFields<Payout>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutUpdateRequest(input: Payout, requestConfig?: AxiosRequestConfiguration): Promise<Payout> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Payout>((resolve, reject) => {
		instance.post<Payout>(
			getUrl(`/payout/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Payout>) => {
			resolve(convertApiFields<Payout>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutUpdateRequestExtended(input: Payout, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Payout>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Payout>>((resolve, reject) => {
		instance.post<Payout>(
			getUrl(`/payout/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Payout>) => {
			resolve({
				data: convertApiFields<Payout>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutDestroyRequest(payoutId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/payout/${payoutId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutDestroyRequestExtended(payoutId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/payout/${payoutId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<PayoutReport>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<PayoutReport>>((resolve, reject) => {
		instance.get<Array<PayoutReport>>(
			getUrl(`/payoutReport`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<PayoutReport>>) => {
			resolve(convertApiFields<Array<PayoutReport>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<PayoutReport>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<PayoutReport>>>((resolve, reject) => {
		instance.get<Array<PayoutReport>>(
			getUrl(`/payoutReport`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<PayoutReport>>) => {
			resolve({
				data: convertApiFields<Array<PayoutReport>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportStoreRequest(input: PayoutReport, requestConfig?: AxiosRequestConfiguration): Promise<PayoutReport> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<PayoutReport>((resolve, reject) => {
		instance.put<PayoutReport>(
			getUrl(`/payoutReport`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<PayoutReport>) => {
			resolve(convertApiFields<PayoutReport>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportStoreRequestExtended(input: PayoutReport, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<PayoutReport>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<PayoutReport>>((resolve, reject) => {
		instance.put<PayoutReport>(
			getUrl(`/payoutReport`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<PayoutReport>) => {
			resolve({
				data: convertApiFields<PayoutReport>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportShowRequest(payoutReportId: number, requestConfig?: AxiosRequestConfiguration): Promise<PayoutReport> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<PayoutReport>((resolve, reject) => {
		instance.get<PayoutReport>(
			getUrl(`/payoutReport/${payoutReportId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<PayoutReport>) => {
			resolve(convertApiFields<PayoutReport>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportShowRequestExtended(payoutReportId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<PayoutReport>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<PayoutReport>>((resolve, reject) => {
		instance.get<PayoutReport>(
			getUrl(`/payoutReport/${payoutReportId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<PayoutReport>) => {
			resolve({
				data: convertApiFields<PayoutReport>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportUpdateRequest(input: PayoutReport, requestConfig?: AxiosRequestConfiguration): Promise<PayoutReport> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<PayoutReport>((resolve, reject) => {
		instance.post<PayoutReport>(
			getUrl(`/payoutReport/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<PayoutReport>) => {
			resolve(convertApiFields<PayoutReport>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportUpdateRequestExtended(input: PayoutReport, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<PayoutReport>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<PayoutReport>>((resolve, reject) => {
		instance.post<PayoutReport>(
			getUrl(`/payoutReport/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<PayoutReport>) => {
			resolve({
				data: convertApiFields<PayoutReport>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportDestroyRequest(payoutReportId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/payoutReport/${payoutReportId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportDestroyRequestExtended(payoutReportId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/payoutReport/${payoutReportId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function importCsvRequest(input: Mappings, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/csv`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function importCsvRequestExtended(input: Mappings, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/csv`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function importPayoutCsvRequest(input: Mappings, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/csv/payout`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function importPayoutCsvRequestExtended(input: Mappings, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/csv/payout`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function listCsvPropertiesRequest(files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/listing`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function listCsvPropertiesRequestExtended(files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/listing`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function listPayoutCsvPropertiesRequest(files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/listing/payout`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function listPayoutCsvPropertiesRequestExtended(files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/listing/payout`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportShowByHashRequest(hash: string, requestConfig?: AxiosRequestConfiguration): Promise<Report> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Report>((resolve, reject) => {
		instance.get<Report>(
			getUrl(`/report/hash/${hash}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Report>) => {
			resolve(convertApiFields<Report>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function reportShowByHashRequestExtended(hash: string, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Report>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Report>>((resolve, reject) => {
		instance.get<Report>(
			getUrl(`/report/hash/${hash}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Report>) => {
			resolve({
				data: convertApiFields<Report>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportShowByHashRequest(hash: string, requestConfig?: AxiosRequestConfiguration): Promise<PayoutReport> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<PayoutReport>((resolve, reject) => {
		instance.get<PayoutReport>(
			getUrl(`/payout/report/hash/${hash}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<PayoutReport>) => {
			resolve(convertApiFields<PayoutReport>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function payoutReportShowByHashRequestExtended(hash: string, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<PayoutReport>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<PayoutReport>>((resolve, reject) => {
		instance.get<PayoutReport>(
			getUrl(`/payout/report/hash/${hash}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<PayoutReport>) => {
			resolve({
				data: convertApiFields<PayoutReport>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function loginRequest(requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.get<void>(
			getUrl(`/login`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function loginRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.get<void>(
			getUrl(`/login`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function whoRequest(requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.get<void>(
			getUrl(`/who`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function whoRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.get<void>(
			getUrl(`/who`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function byeRequest(requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.get<void>(
			getUrl(`/bye`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function byeRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.get<void>(
			getUrl(`/bye`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function exportCsvRequest(hash: any, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.get<void>(
			getUrl(`/export/${hash}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function exportCsvRequestExtended(hash: any, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.get<void>(
			getUrl(`/export/${hash}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<User>>) => Promise<Array<User>>): { [key: string]: (props) => Promise<Array<User>> } {
	return { userIndex: (props) => request(props, userIndexRequest) };
}

export function userIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<User>>>) => Promise<ExtendedResponseData<Array<User>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<User>>> } {
	return { userIndexExtended: (props) => request(props, userIndexRequestExtended) };
}

export function userStoreRequestLoaderChunk(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { [key: string]: (props) => Promise<User> } {
	return { userStore: (props) => request(props, userStoreRequest) };
}

export function userStoreRequestExtendedLoaderChunk(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { [key: string]: (props) => Promise<ExtendedResponseData<User>> } {
	return { userStoreExtended: (props) => request(props, userStoreRequestExtended) };
}

export function userShowRequestLoaderChunk(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { [key: string]: (props) => Promise<User> } {
	return { userShow: (props) => request(props, userShowRequest) };
}

export function userShowRequestExtendedLoaderChunk(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { [key: string]: (props) => Promise<ExtendedResponseData<User>> } {
	return { userShowExtended: (props) => request(props, userShowRequestExtended) };
}

export function userUpdateRequestLoaderChunk(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { [key: string]: (props) => Promise<User> } {
	return { userUpdate: (props) => request(props, userUpdateRequest) };
}

export function userUpdateRequestExtendedLoaderChunk(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { [key: string]: (props) => Promise<ExtendedResponseData<User>> } {
	return { userUpdateExtended: (props) => request(props, userUpdateRequestExtended) };
}

export function userDestroyRequestLoaderChunk(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { userDestroy: (props) => request(props, userDestroyRequest) };
}

export function userDestroyRequestExtendedLoaderChunk(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { userDestroyExtended: (props) => request(props, userDestroyRequestExtended) };
}

export function propertyIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Property>>) => Promise<Array<Property>>): { [key: string]: (props) => Promise<Array<Property>> } {
	return { propertyIndex: (props) => request(props, propertyIndexRequest) };
}

export function propertyIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Property>>>) => Promise<ExtendedResponseData<Array<Property>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Property>>> } {
	return { propertyIndexExtended: (props) => request(props, propertyIndexRequestExtended) };
}

export function propertyStoreRequestLoaderChunk(request: (props, _: (input: Property, requestConfig?: AxiosRequestConfiguration) => Promise<Property>) => Promise<Property>): { [key: string]: (props) => Promise<Property> } {
	return { propertyStore: (props) => request(props, propertyStoreRequest) };
}

export function propertyStoreRequestExtendedLoaderChunk(request: (props, _: (input: Property, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Property>>) => Promise<ExtendedResponseData<Property>>): { [key: string]: (props) => Promise<ExtendedResponseData<Property>> } {
	return { propertyStoreExtended: (props) => request(props, propertyStoreRequestExtended) };
}

export function propertyShowRequestLoaderChunk(request: (props, _: (propertyId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Property>) => Promise<Property>): { [key: string]: (props) => Promise<Property> } {
	return { propertyShow: (props) => request(props, propertyShowRequest) };
}

export function propertyShowRequestExtendedLoaderChunk(request: (props, _: (propertyId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Property>>) => Promise<ExtendedResponseData<Property>>): { [key: string]: (props) => Promise<ExtendedResponseData<Property>> } {
	return { propertyShowExtended: (props) => request(props, propertyShowRequestExtended) };
}

export function propertyUpdateRequestLoaderChunk(request: (props, _: (input: Property, requestConfig?: AxiosRequestConfiguration) => Promise<Property>) => Promise<Property>): { [key: string]: (props) => Promise<Property> } {
	return { propertyUpdate: (props) => request(props, propertyUpdateRequest) };
}

export function propertyUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Property, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Property>>) => Promise<ExtendedResponseData<Property>>): { [key: string]: (props) => Promise<ExtendedResponseData<Property>> } {
	return { propertyUpdateExtended: (props) => request(props, propertyUpdateRequestExtended) };
}

export function propertyDestroyRequestLoaderChunk(request: (props, _: (propertyId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { propertyDestroy: (props) => request(props, propertyDestroyRequest) };
}

export function propertyDestroyRequestExtendedLoaderChunk(request: (props, _: (propertyId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { propertyDestroyExtended: (props) => request(props, propertyDestroyRequestExtended) };
}

export function propertyEntryIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<PropertyEntry>>) => Promise<Array<PropertyEntry>>): { [key: string]: (props) => Promise<Array<PropertyEntry>> } {
	return { propertyEntryIndex: (props) => request(props, propertyEntryIndexRequest) };
}

export function propertyEntryIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<PropertyEntry>>>) => Promise<ExtendedResponseData<Array<PropertyEntry>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<PropertyEntry>>> } {
	return { propertyEntryIndexExtended: (props) => request(props, propertyEntryIndexRequestExtended) };
}

export function propertyEntryStoreRequestLoaderChunk(request: (props, _: (input: PropertyEntry, requestConfig?: AxiosRequestConfiguration) => Promise<PropertyEntry>) => Promise<PropertyEntry>): { [key: string]: (props) => Promise<PropertyEntry> } {
	return { propertyEntryStore: (props) => request(props, propertyEntryStoreRequest) };
}

export function propertyEntryStoreRequestExtendedLoaderChunk(request: (props, _: (input: PropertyEntry, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PropertyEntry>>) => Promise<ExtendedResponseData<PropertyEntry>>): { [key: string]: (props) => Promise<ExtendedResponseData<PropertyEntry>> } {
	return { propertyEntryStoreExtended: (props) => request(props, propertyEntryStoreRequestExtended) };
}

export function propertyEntryShowRequestLoaderChunk(request: (props, _: (propertyEntryId: number, requestConfig?: AxiosRequestConfiguration) => Promise<PropertyEntry>) => Promise<PropertyEntry>): { [key: string]: (props) => Promise<PropertyEntry> } {
	return { propertyEntryShow: (props) => request(props, propertyEntryShowRequest) };
}

export function propertyEntryShowRequestExtendedLoaderChunk(request: (props, _: (propertyEntryId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PropertyEntry>>) => Promise<ExtendedResponseData<PropertyEntry>>): { [key: string]: (props) => Promise<ExtendedResponseData<PropertyEntry>> } {
	return { propertyEntryShowExtended: (props) => request(props, propertyEntryShowRequestExtended) };
}

export function propertyEntryUpdateRequestLoaderChunk(request: (props, _: (input: PropertyEntry, requestConfig?: AxiosRequestConfiguration) => Promise<PropertyEntry>) => Promise<PropertyEntry>): { [key: string]: (props) => Promise<PropertyEntry> } {
	return { propertyEntryUpdate: (props) => request(props, propertyEntryUpdateRequest) };
}

export function propertyEntryUpdateRequestExtendedLoaderChunk(request: (props, _: (input: PropertyEntry, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PropertyEntry>>) => Promise<ExtendedResponseData<PropertyEntry>>): { [key: string]: (props) => Promise<ExtendedResponseData<PropertyEntry>> } {
	return { propertyEntryUpdateExtended: (props) => request(props, propertyEntryUpdateRequestExtended) };
}

export function propertyEntryDestroyRequestLoaderChunk(request: (props, _: (propertyEntryId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { propertyEntryDestroy: (props) => request(props, propertyEntryDestroyRequest) };
}

export function propertyEntryDestroyRequestExtendedLoaderChunk(request: (props, _: (propertyEntryId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { propertyEntryDestroyExtended: (props) => request(props, propertyEntryDestroyRequestExtended) };
}

export function reportIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Report>>) => Promise<Array<Report>>): { [key: string]: (props) => Promise<Array<Report>> } {
	return { reportIndex: (props) => request(props, reportIndexRequest) };
}

export function reportIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Report>>>) => Promise<ExtendedResponseData<Array<Report>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Report>>> } {
	return { reportIndexExtended: (props) => request(props, reportIndexRequestExtended) };
}

export function reportStoreRequestLoaderChunk(request: (props, _: (input: Report, requestConfig?: AxiosRequestConfiguration) => Promise<Report>) => Promise<Report>): { [key: string]: (props) => Promise<Report> } {
	return { reportStore: (props) => request(props, reportStoreRequest) };
}

export function reportStoreRequestExtendedLoaderChunk(request: (props, _: (input: Report, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Report>>) => Promise<ExtendedResponseData<Report>>): { [key: string]: (props) => Promise<ExtendedResponseData<Report>> } {
	return { reportStoreExtended: (props) => request(props, reportStoreRequestExtended) };
}

export function reportShowRequestLoaderChunk(request: (props, _: (reportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Report>) => Promise<Report>): { [key: string]: (props) => Promise<Report> } {
	return { reportShow: (props) => request(props, reportShowRequest) };
}

export function reportShowRequestExtendedLoaderChunk(request: (props, _: (reportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Report>>) => Promise<ExtendedResponseData<Report>>): { [key: string]: (props) => Promise<ExtendedResponseData<Report>> } {
	return { reportShowExtended: (props) => request(props, reportShowRequestExtended) };
}

export function reportUpdateRequestLoaderChunk(request: (props, _: (input: Report, requestConfig?: AxiosRequestConfiguration) => Promise<Report>) => Promise<Report>): { [key: string]: (props) => Promise<Report> } {
	return { reportUpdate: (props) => request(props, reportUpdateRequest) };
}

export function reportUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Report, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Report>>) => Promise<ExtendedResponseData<Report>>): { [key: string]: (props) => Promise<ExtendedResponseData<Report>> } {
	return { reportUpdateExtended: (props) => request(props, reportUpdateRequestExtended) };
}

export function reportDestroyRequestLoaderChunk(request: (props, _: (reportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { reportDestroy: (props) => request(props, reportDestroyRequest) };
}

export function reportDestroyRequestExtendedLoaderChunk(request: (props, _: (reportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { reportDestroyExtended: (props) => request(props, reportDestroyRequestExtended) };
}

export function payoutIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Payout>>) => Promise<Array<Payout>>): { [key: string]: (props) => Promise<Array<Payout>> } {
	return { payoutIndex: (props) => request(props, payoutIndexRequest) };
}

export function payoutIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Payout>>>) => Promise<ExtendedResponseData<Array<Payout>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Payout>>> } {
	return { payoutIndexExtended: (props) => request(props, payoutIndexRequestExtended) };
}

export function payoutStoreRequestLoaderChunk(request: (props, _: (input: Payout, requestConfig?: AxiosRequestConfiguration) => Promise<Payout>) => Promise<Payout>): { [key: string]: (props) => Promise<Payout> } {
	return { payoutStore: (props) => request(props, payoutStoreRequest) };
}

export function payoutStoreRequestExtendedLoaderChunk(request: (props, _: (input: Payout, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Payout>>) => Promise<ExtendedResponseData<Payout>>): { [key: string]: (props) => Promise<ExtendedResponseData<Payout>> } {
	return { payoutStoreExtended: (props) => request(props, payoutStoreRequestExtended) };
}

export function payoutShowRequestLoaderChunk(request: (props, _: (payoutId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Payout>) => Promise<Payout>): { [key: string]: (props) => Promise<Payout> } {
	return { payoutShow: (props) => request(props, payoutShowRequest) };
}

export function payoutShowRequestExtendedLoaderChunk(request: (props, _: (payoutId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Payout>>) => Promise<ExtendedResponseData<Payout>>): { [key: string]: (props) => Promise<ExtendedResponseData<Payout>> } {
	return { payoutShowExtended: (props) => request(props, payoutShowRequestExtended) };
}

export function payoutUpdateRequestLoaderChunk(request: (props, _: (input: Payout, requestConfig?: AxiosRequestConfiguration) => Promise<Payout>) => Promise<Payout>): { [key: string]: (props) => Promise<Payout> } {
	return { payoutUpdate: (props) => request(props, payoutUpdateRequest) };
}

export function payoutUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Payout, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Payout>>) => Promise<ExtendedResponseData<Payout>>): { [key: string]: (props) => Promise<ExtendedResponseData<Payout>> } {
	return { payoutUpdateExtended: (props) => request(props, payoutUpdateRequestExtended) };
}

export function payoutDestroyRequestLoaderChunk(request: (props, _: (payoutId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { payoutDestroy: (props) => request(props, payoutDestroyRequest) };
}

export function payoutDestroyRequestExtendedLoaderChunk(request: (props, _: (payoutId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { payoutDestroyExtended: (props) => request(props, payoutDestroyRequestExtended) };
}

export function payoutReportIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<PayoutReport>>) => Promise<Array<PayoutReport>>): { [key: string]: (props) => Promise<Array<PayoutReport>> } {
	return { payoutReportIndex: (props) => request(props, payoutReportIndexRequest) };
}

export function payoutReportIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<PayoutReport>>>) => Promise<ExtendedResponseData<Array<PayoutReport>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<PayoutReport>>> } {
	return { payoutReportIndexExtended: (props) => request(props, payoutReportIndexRequestExtended) };
}

export function payoutReportStoreRequestLoaderChunk(request: (props, _: (input: PayoutReport, requestConfig?: AxiosRequestConfiguration) => Promise<PayoutReport>) => Promise<PayoutReport>): { [key: string]: (props) => Promise<PayoutReport> } {
	return { payoutReportStore: (props) => request(props, payoutReportStoreRequest) };
}

export function payoutReportStoreRequestExtendedLoaderChunk(request: (props, _: (input: PayoutReport, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PayoutReport>>) => Promise<ExtendedResponseData<PayoutReport>>): { [key: string]: (props) => Promise<ExtendedResponseData<PayoutReport>> } {
	return { payoutReportStoreExtended: (props) => request(props, payoutReportStoreRequestExtended) };
}

export function payoutReportShowRequestLoaderChunk(request: (props, _: (payoutReportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<PayoutReport>) => Promise<PayoutReport>): { [key: string]: (props) => Promise<PayoutReport> } {
	return { payoutReportShow: (props) => request(props, payoutReportShowRequest) };
}

export function payoutReportShowRequestExtendedLoaderChunk(request: (props, _: (payoutReportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PayoutReport>>) => Promise<ExtendedResponseData<PayoutReport>>): { [key: string]: (props) => Promise<ExtendedResponseData<PayoutReport>> } {
	return { payoutReportShowExtended: (props) => request(props, payoutReportShowRequestExtended) };
}

export function payoutReportUpdateRequestLoaderChunk(request: (props, _: (input: PayoutReport, requestConfig?: AxiosRequestConfiguration) => Promise<PayoutReport>) => Promise<PayoutReport>): { [key: string]: (props) => Promise<PayoutReport> } {
	return { payoutReportUpdate: (props) => request(props, payoutReportUpdateRequest) };
}

export function payoutReportUpdateRequestExtendedLoaderChunk(request: (props, _: (input: PayoutReport, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PayoutReport>>) => Promise<ExtendedResponseData<PayoutReport>>): { [key: string]: (props) => Promise<ExtendedResponseData<PayoutReport>> } {
	return { payoutReportUpdateExtended: (props) => request(props, payoutReportUpdateRequestExtended) };
}

export function payoutReportDestroyRequestLoaderChunk(request: (props, _: (payoutReportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { payoutReportDestroy: (props) => request(props, payoutReportDestroyRequest) };
}

export function payoutReportDestroyRequestExtendedLoaderChunk(request: (props, _: (payoutReportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { payoutReportDestroyExtended: (props) => request(props, payoutReportDestroyRequestExtended) };
}

export function importCsvRequestLoaderChunk(request: (props, _: (input: Mappings, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { importCsv: (props) => request(props, importCsvRequest) };
}

export function importCsvRequestExtendedLoaderChunk(request: (props, _: (input: Mappings, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { importCsvExtended: (props) => request(props, importCsvRequestExtended) };
}

export function importPayoutCsvRequestLoaderChunk(request: (props, _: (input: Mappings, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { importPayoutCsv: (props) => request(props, importPayoutCsvRequest) };
}

export function importPayoutCsvRequestExtendedLoaderChunk(request: (props, _: (input: Mappings, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { importPayoutCsvExtended: (props) => request(props, importPayoutCsvRequestExtended) };
}

export function listCsvPropertiesRequestLoaderChunk(request: (props, _: (files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { listCsvProperties: (props) => request(props, listCsvPropertiesRequest) };
}

export function listCsvPropertiesRequestExtendedLoaderChunk(request: (props, _: (files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { listCsvPropertiesExtended: (props) => request(props, listCsvPropertiesRequestExtended) };
}

export function listPayoutCsvPropertiesRequestLoaderChunk(request: (props, _: (files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { listPayoutCsvProperties: (props) => request(props, listPayoutCsvPropertiesRequest) };
}

export function listPayoutCsvPropertiesRequestExtendedLoaderChunk(request: (props, _: (files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { listPayoutCsvPropertiesExtended: (props) => request(props, listPayoutCsvPropertiesRequestExtended) };
}

export function reportShowByHashRequestLoaderChunk(request: (props, _: (hash: string, requestConfig?: AxiosRequestConfiguration) => Promise<Report>) => Promise<Report>): { [key: string]: (props) => Promise<Report> } {
	return { reportShowByHash: (props) => request(props, reportShowByHashRequest) };
}

export function reportShowByHashRequestExtendedLoaderChunk(request: (props, _: (hash: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Report>>) => Promise<ExtendedResponseData<Report>>): { [key: string]: (props) => Promise<ExtendedResponseData<Report>> } {
	return { reportShowByHashExtended: (props) => request(props, reportShowByHashRequestExtended) };
}

export function payoutReportShowByHashRequestLoaderChunk(request: (props, _: (hash: string, requestConfig?: AxiosRequestConfiguration) => Promise<PayoutReport>) => Promise<PayoutReport>): { [key: string]: (props) => Promise<PayoutReport> } {
	return { payoutReportShowByHash: (props) => request(props, payoutReportShowByHashRequest) };
}

export function payoutReportShowByHashRequestExtendedLoaderChunk(request: (props, _: (hash: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PayoutReport>>) => Promise<ExtendedResponseData<PayoutReport>>): { [key: string]: (props) => Promise<ExtendedResponseData<PayoutReport>> } {
	return { payoutReportShowByHashExtended: (props) => request(props, payoutReportShowByHashRequestExtended) };
}

export function loginRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { login: (props) => request(props, loginRequest) };
}

export function loginRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { loginExtended: (props) => request(props, loginRequestExtended) };
}

export function whoRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { who: (props) => request(props, whoRequest) };
}

export function whoRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { whoExtended: (props) => request(props, whoRequestExtended) };
}

export function byeRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { bye: (props) => request(props, byeRequest) };
}

export function byeRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { byeExtended: (props) => request(props, byeRequestExtended) };
}

export function exportCsvRequestLoaderChunk(request: (props, _: (hash: any, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { exportCsv: (props) => request(props, exportCsvRequest) };
}

export function exportCsvRequestExtendedLoaderChunk(request: (props, _: (hash: any, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { exportCsvExtended: (props) => request(props, exportCsvRequestExtended) };
}

export function userIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<User>>) => Promise<Array<User>>): { promises: { [key: string]: (props) => Promise<Array<User>> } } {
	return { promises: userIndexRequestLoaderChunk(request) };
}

export function userIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<User>>>) => Promise<ExtendedResponseData<Array<User>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<User>>> } } {
	return { promises: userIndexRequestExtendedLoaderChunk(request) };
}

export function userStoreRequestLoader(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { promises: { [key: string]: (props) => Promise<User> } } {
	return { promises: userStoreRequestLoaderChunk(request) };
}

export function userStoreRequestExtendedLoader(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<User>> } } {
	return { promises: userStoreRequestExtendedLoaderChunk(request) };
}

export function userShowRequestLoader(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { promises: { [key: string]: (props) => Promise<User> } } {
	return { promises: userShowRequestLoaderChunk(request) };
}

export function userShowRequestExtendedLoader(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<User>> } } {
	return { promises: userShowRequestExtendedLoaderChunk(request) };
}

export function userUpdateRequestLoader(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { promises: { [key: string]: (props) => Promise<User> } } {
	return { promises: userUpdateRequestLoaderChunk(request) };
}

export function userUpdateRequestExtendedLoader(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<User>> } } {
	return { promises: userUpdateRequestExtendedLoaderChunk(request) };
}

export function userDestroyRequestLoader(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: userDestroyRequestLoaderChunk(request) };
}

export function userDestroyRequestExtendedLoader(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: userDestroyRequestExtendedLoaderChunk(request) };
}

export function propertyIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Property>>) => Promise<Array<Property>>): { promises: { [key: string]: (props) => Promise<Array<Property>> } } {
	return { promises: propertyIndexRequestLoaderChunk(request) };
}

export function propertyIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Property>>>) => Promise<ExtendedResponseData<Array<Property>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Property>>> } } {
	return { promises: propertyIndexRequestExtendedLoaderChunk(request) };
}

export function propertyStoreRequestLoader(request: (props, _: (input: Property, requestConfig?: AxiosRequestConfiguration) => Promise<Property>) => Promise<Property>): { promises: { [key: string]: (props) => Promise<Property> } } {
	return { promises: propertyStoreRequestLoaderChunk(request) };
}

export function propertyStoreRequestExtendedLoader(request: (props, _: (input: Property, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Property>>) => Promise<ExtendedResponseData<Property>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Property>> } } {
	return { promises: propertyStoreRequestExtendedLoaderChunk(request) };
}

export function propertyShowRequestLoader(request: (props, _: (propertyId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Property>) => Promise<Property>): { promises: { [key: string]: (props) => Promise<Property> } } {
	return { promises: propertyShowRequestLoaderChunk(request) };
}

export function propertyShowRequestExtendedLoader(request: (props, _: (propertyId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Property>>) => Promise<ExtendedResponseData<Property>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Property>> } } {
	return { promises: propertyShowRequestExtendedLoaderChunk(request) };
}

export function propertyUpdateRequestLoader(request: (props, _: (input: Property, requestConfig?: AxiosRequestConfiguration) => Promise<Property>) => Promise<Property>): { promises: { [key: string]: (props) => Promise<Property> } } {
	return { promises: propertyUpdateRequestLoaderChunk(request) };
}

export function propertyUpdateRequestExtendedLoader(request: (props, _: (input: Property, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Property>>) => Promise<ExtendedResponseData<Property>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Property>> } } {
	return { promises: propertyUpdateRequestExtendedLoaderChunk(request) };
}

export function propertyDestroyRequestLoader(request: (props, _: (propertyId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: propertyDestroyRequestLoaderChunk(request) };
}

export function propertyDestroyRequestExtendedLoader(request: (props, _: (propertyId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: propertyDestroyRequestExtendedLoaderChunk(request) };
}

export function propertyEntryIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<PropertyEntry>>) => Promise<Array<PropertyEntry>>): { promises: { [key: string]: (props) => Promise<Array<PropertyEntry>> } } {
	return { promises: propertyEntryIndexRequestLoaderChunk(request) };
}

export function propertyEntryIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<PropertyEntry>>>) => Promise<ExtendedResponseData<Array<PropertyEntry>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<PropertyEntry>>> } } {
	return { promises: propertyEntryIndexRequestExtendedLoaderChunk(request) };
}

export function propertyEntryStoreRequestLoader(request: (props, _: (input: PropertyEntry, requestConfig?: AxiosRequestConfiguration) => Promise<PropertyEntry>) => Promise<PropertyEntry>): { promises: { [key: string]: (props) => Promise<PropertyEntry> } } {
	return { promises: propertyEntryStoreRequestLoaderChunk(request) };
}

export function propertyEntryStoreRequestExtendedLoader(request: (props, _: (input: PropertyEntry, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PropertyEntry>>) => Promise<ExtendedResponseData<PropertyEntry>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<PropertyEntry>> } } {
	return { promises: propertyEntryStoreRequestExtendedLoaderChunk(request) };
}

export function propertyEntryShowRequestLoader(request: (props, _: (propertyEntryId: number, requestConfig?: AxiosRequestConfiguration) => Promise<PropertyEntry>) => Promise<PropertyEntry>): { promises: { [key: string]: (props) => Promise<PropertyEntry> } } {
	return { promises: propertyEntryShowRequestLoaderChunk(request) };
}

export function propertyEntryShowRequestExtendedLoader(request: (props, _: (propertyEntryId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PropertyEntry>>) => Promise<ExtendedResponseData<PropertyEntry>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<PropertyEntry>> } } {
	return { promises: propertyEntryShowRequestExtendedLoaderChunk(request) };
}

export function propertyEntryUpdateRequestLoader(request: (props, _: (input: PropertyEntry, requestConfig?: AxiosRequestConfiguration) => Promise<PropertyEntry>) => Promise<PropertyEntry>): { promises: { [key: string]: (props) => Promise<PropertyEntry> } } {
	return { promises: propertyEntryUpdateRequestLoaderChunk(request) };
}

export function propertyEntryUpdateRequestExtendedLoader(request: (props, _: (input: PropertyEntry, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PropertyEntry>>) => Promise<ExtendedResponseData<PropertyEntry>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<PropertyEntry>> } } {
	return { promises: propertyEntryUpdateRequestExtendedLoaderChunk(request) };
}

export function propertyEntryDestroyRequestLoader(request: (props, _: (propertyEntryId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: propertyEntryDestroyRequestLoaderChunk(request) };
}

export function propertyEntryDestroyRequestExtendedLoader(request: (props, _: (propertyEntryId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: propertyEntryDestroyRequestExtendedLoaderChunk(request) };
}

export function reportIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Report>>) => Promise<Array<Report>>): { promises: { [key: string]: (props) => Promise<Array<Report>> } } {
	return { promises: reportIndexRequestLoaderChunk(request) };
}

export function reportIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Report>>>) => Promise<ExtendedResponseData<Array<Report>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Report>>> } } {
	return { promises: reportIndexRequestExtendedLoaderChunk(request) };
}

export function reportStoreRequestLoader(request: (props, _: (input: Report, requestConfig?: AxiosRequestConfiguration) => Promise<Report>) => Promise<Report>): { promises: { [key: string]: (props) => Promise<Report> } } {
	return { promises: reportStoreRequestLoaderChunk(request) };
}

export function reportStoreRequestExtendedLoader(request: (props, _: (input: Report, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Report>>) => Promise<ExtendedResponseData<Report>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Report>> } } {
	return { promises: reportStoreRequestExtendedLoaderChunk(request) };
}

export function reportShowRequestLoader(request: (props, _: (reportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Report>) => Promise<Report>): { promises: { [key: string]: (props) => Promise<Report> } } {
	return { promises: reportShowRequestLoaderChunk(request) };
}

export function reportShowRequestExtendedLoader(request: (props, _: (reportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Report>>) => Promise<ExtendedResponseData<Report>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Report>> } } {
	return { promises: reportShowRequestExtendedLoaderChunk(request) };
}

export function reportUpdateRequestLoader(request: (props, _: (input: Report, requestConfig?: AxiosRequestConfiguration) => Promise<Report>) => Promise<Report>): { promises: { [key: string]: (props) => Promise<Report> } } {
	return { promises: reportUpdateRequestLoaderChunk(request) };
}

export function reportUpdateRequestExtendedLoader(request: (props, _: (input: Report, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Report>>) => Promise<ExtendedResponseData<Report>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Report>> } } {
	return { promises: reportUpdateRequestExtendedLoaderChunk(request) };
}

export function reportDestroyRequestLoader(request: (props, _: (reportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: reportDestroyRequestLoaderChunk(request) };
}

export function reportDestroyRequestExtendedLoader(request: (props, _: (reportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: reportDestroyRequestExtendedLoaderChunk(request) };
}

export function payoutIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Payout>>) => Promise<Array<Payout>>): { promises: { [key: string]: (props) => Promise<Array<Payout>> } } {
	return { promises: payoutIndexRequestLoaderChunk(request) };
}

export function payoutIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Payout>>>) => Promise<ExtendedResponseData<Array<Payout>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Payout>>> } } {
	return { promises: payoutIndexRequestExtendedLoaderChunk(request) };
}

export function payoutStoreRequestLoader(request: (props, _: (input: Payout, requestConfig?: AxiosRequestConfiguration) => Promise<Payout>) => Promise<Payout>): { promises: { [key: string]: (props) => Promise<Payout> } } {
	return { promises: payoutStoreRequestLoaderChunk(request) };
}

export function payoutStoreRequestExtendedLoader(request: (props, _: (input: Payout, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Payout>>) => Promise<ExtendedResponseData<Payout>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Payout>> } } {
	return { promises: payoutStoreRequestExtendedLoaderChunk(request) };
}

export function payoutShowRequestLoader(request: (props, _: (payoutId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Payout>) => Promise<Payout>): { promises: { [key: string]: (props) => Promise<Payout> } } {
	return { promises: payoutShowRequestLoaderChunk(request) };
}

export function payoutShowRequestExtendedLoader(request: (props, _: (payoutId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Payout>>) => Promise<ExtendedResponseData<Payout>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Payout>> } } {
	return { promises: payoutShowRequestExtendedLoaderChunk(request) };
}

export function payoutUpdateRequestLoader(request: (props, _: (input: Payout, requestConfig?: AxiosRequestConfiguration) => Promise<Payout>) => Promise<Payout>): { promises: { [key: string]: (props) => Promise<Payout> } } {
	return { promises: payoutUpdateRequestLoaderChunk(request) };
}

export function payoutUpdateRequestExtendedLoader(request: (props, _: (input: Payout, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Payout>>) => Promise<ExtendedResponseData<Payout>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Payout>> } } {
	return { promises: payoutUpdateRequestExtendedLoaderChunk(request) };
}

export function payoutDestroyRequestLoader(request: (props, _: (payoutId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: payoutDestroyRequestLoaderChunk(request) };
}

export function payoutDestroyRequestExtendedLoader(request: (props, _: (payoutId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: payoutDestroyRequestExtendedLoaderChunk(request) };
}

export function payoutReportIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<PayoutReport>>) => Promise<Array<PayoutReport>>): { promises: { [key: string]: (props) => Promise<Array<PayoutReport>> } } {
	return { promises: payoutReportIndexRequestLoaderChunk(request) };
}

export function payoutReportIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<PayoutReport>>>) => Promise<ExtendedResponseData<Array<PayoutReport>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<PayoutReport>>> } } {
	return { promises: payoutReportIndexRequestExtendedLoaderChunk(request) };
}

export function payoutReportStoreRequestLoader(request: (props, _: (input: PayoutReport, requestConfig?: AxiosRequestConfiguration) => Promise<PayoutReport>) => Promise<PayoutReport>): { promises: { [key: string]: (props) => Promise<PayoutReport> } } {
	return { promises: payoutReportStoreRequestLoaderChunk(request) };
}

export function payoutReportStoreRequestExtendedLoader(request: (props, _: (input: PayoutReport, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PayoutReport>>) => Promise<ExtendedResponseData<PayoutReport>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<PayoutReport>> } } {
	return { promises: payoutReportStoreRequestExtendedLoaderChunk(request) };
}

export function payoutReportShowRequestLoader(request: (props, _: (payoutReportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<PayoutReport>) => Promise<PayoutReport>): { promises: { [key: string]: (props) => Promise<PayoutReport> } } {
	return { promises: payoutReportShowRequestLoaderChunk(request) };
}

export function payoutReportShowRequestExtendedLoader(request: (props, _: (payoutReportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PayoutReport>>) => Promise<ExtendedResponseData<PayoutReport>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<PayoutReport>> } } {
	return { promises: payoutReportShowRequestExtendedLoaderChunk(request) };
}

export function payoutReportUpdateRequestLoader(request: (props, _: (input: PayoutReport, requestConfig?: AxiosRequestConfiguration) => Promise<PayoutReport>) => Promise<PayoutReport>): { promises: { [key: string]: (props) => Promise<PayoutReport> } } {
	return { promises: payoutReportUpdateRequestLoaderChunk(request) };
}

export function payoutReportUpdateRequestExtendedLoader(request: (props, _: (input: PayoutReport, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PayoutReport>>) => Promise<ExtendedResponseData<PayoutReport>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<PayoutReport>> } } {
	return { promises: payoutReportUpdateRequestExtendedLoaderChunk(request) };
}

export function payoutReportDestroyRequestLoader(request: (props, _: (payoutReportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: payoutReportDestroyRequestLoaderChunk(request) };
}

export function payoutReportDestroyRequestExtendedLoader(request: (props, _: (payoutReportId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: payoutReportDestroyRequestExtendedLoaderChunk(request) };
}

export function importCsvRequestLoader(request: (props, _: (input: Mappings, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: importCsvRequestLoaderChunk(request) };
}

export function importCsvRequestExtendedLoader(request: (props, _: (input: Mappings, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: importCsvRequestExtendedLoaderChunk(request) };
}

export function importPayoutCsvRequestLoader(request: (props, _: (input: Mappings, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: importPayoutCsvRequestLoaderChunk(request) };
}

export function importPayoutCsvRequestExtendedLoader(request: (props, _: (input: Mappings, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: importPayoutCsvRequestExtendedLoaderChunk(request) };
}

export function listCsvPropertiesRequestLoader(request: (props, _: (files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: listCsvPropertiesRequestLoaderChunk(request) };
}

export function listCsvPropertiesRequestExtendedLoader(request: (props, _: (files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: listCsvPropertiesRequestExtendedLoaderChunk(request) };
}

export function listPayoutCsvPropertiesRequestLoader(request: (props, _: (files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: listPayoutCsvPropertiesRequestLoaderChunk(request) };
}

export function listPayoutCsvPropertiesRequestExtendedLoader(request: (props, _: (files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: listPayoutCsvPropertiesRequestExtendedLoaderChunk(request) };
}

export function reportShowByHashRequestLoader(request: (props, _: (hash: string, requestConfig?: AxiosRequestConfiguration) => Promise<Report>) => Promise<Report>): { promises: { [key: string]: (props) => Promise<Report> } } {
	return { promises: reportShowByHashRequestLoaderChunk(request) };
}

export function reportShowByHashRequestExtendedLoader(request: (props, _: (hash: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Report>>) => Promise<ExtendedResponseData<Report>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Report>> } } {
	return { promises: reportShowByHashRequestExtendedLoaderChunk(request) };
}

export function payoutReportShowByHashRequestLoader(request: (props, _: (hash: string, requestConfig?: AxiosRequestConfiguration) => Promise<PayoutReport>) => Promise<PayoutReport>): { promises: { [key: string]: (props) => Promise<PayoutReport> } } {
	return { promises: payoutReportShowByHashRequestLoaderChunk(request) };
}

export function payoutReportShowByHashRequestExtendedLoader(request: (props, _: (hash: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<PayoutReport>>) => Promise<ExtendedResponseData<PayoutReport>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<PayoutReport>> } } {
	return { promises: payoutReportShowByHashRequestExtendedLoaderChunk(request) };
}

export function loginRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: loginRequestLoaderChunk(request) };
}

export function loginRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: loginRequestExtendedLoaderChunk(request) };
}

export function whoRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: whoRequestLoaderChunk(request) };
}

export function whoRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: whoRequestExtendedLoaderChunk(request) };
}

export function byeRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: byeRequestLoaderChunk(request) };
}

export function byeRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: byeRequestExtendedLoaderChunk(request) };
}

export function exportCsvRequestLoader(request: (props, _: (hash: any, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: exportCsvRequestLoaderChunk(request) };
}

export function exportCsvRequestExtendedLoader(request: (props, _: (hash: any, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: exportCsvRequestExtendedLoaderChunk(request) };
}

export interface UserIndexRequestPropsMapper {
	userIndex: Array<User>
}

export interface UserIndexRequestExtendedPropsMapper {
	userIndexExtended: ExtendedResponseData<Array<User>>
}

export interface UserStoreRequestPropsMapper {
	userStore: User
}

export interface UserStoreRequestExtendedPropsMapper {
	userStoreExtended: ExtendedResponseData<User>
}

export interface UserShowRequestPropsMapper {
	userShow: User
}

export interface UserShowRequestExtendedPropsMapper {
	userShowExtended: ExtendedResponseData<User>
}

export interface UserUpdateRequestPropsMapper {
	userUpdate: User
}

export interface UserUpdateRequestExtendedPropsMapper {
	userUpdateExtended: ExtendedResponseData<User>
}

export interface UserDestroyRequestPropsMapper {
	userDestroy: void
}

export interface UserDestroyRequestExtendedPropsMapper {
	userDestroyExtended: ExtendedResponseData<void>
}

export interface PropertyIndexRequestPropsMapper {
	propertyIndex: Array<Property>
}

export interface PropertyIndexRequestExtendedPropsMapper {
	propertyIndexExtended: ExtendedResponseData<Array<Property>>
}

export interface PropertyStoreRequestPropsMapper {
	propertyStore: Property
}

export interface PropertyStoreRequestExtendedPropsMapper {
	propertyStoreExtended: ExtendedResponseData<Property>
}

export interface PropertyShowRequestPropsMapper {
	propertyShow: Property
}

export interface PropertyShowRequestExtendedPropsMapper {
	propertyShowExtended: ExtendedResponseData<Property>
}

export interface PropertyUpdateRequestPropsMapper {
	propertyUpdate: Property
}

export interface PropertyUpdateRequestExtendedPropsMapper {
	propertyUpdateExtended: ExtendedResponseData<Property>
}

export interface PropertyDestroyRequestPropsMapper {
	propertyDestroy: void
}

export interface PropertyDestroyRequestExtendedPropsMapper {
	propertyDestroyExtended: ExtendedResponseData<void>
}

export interface PropertyEntryIndexRequestPropsMapper {
	propertyEntryIndex: Array<PropertyEntry>
}

export interface PropertyEntryIndexRequestExtendedPropsMapper {
	propertyEntryIndexExtended: ExtendedResponseData<Array<PropertyEntry>>
}

export interface PropertyEntryStoreRequestPropsMapper {
	propertyEntryStore: PropertyEntry
}

export interface PropertyEntryStoreRequestExtendedPropsMapper {
	propertyEntryStoreExtended: ExtendedResponseData<PropertyEntry>
}

export interface PropertyEntryShowRequestPropsMapper {
	propertyEntryShow: PropertyEntry
}

export interface PropertyEntryShowRequestExtendedPropsMapper {
	propertyEntryShowExtended: ExtendedResponseData<PropertyEntry>
}

export interface PropertyEntryUpdateRequestPropsMapper {
	propertyEntryUpdate: PropertyEntry
}

export interface PropertyEntryUpdateRequestExtendedPropsMapper {
	propertyEntryUpdateExtended: ExtendedResponseData<PropertyEntry>
}

export interface PropertyEntryDestroyRequestPropsMapper {
	propertyEntryDestroy: void
}

export interface PropertyEntryDestroyRequestExtendedPropsMapper {
	propertyEntryDestroyExtended: ExtendedResponseData<void>
}

export interface ReportIndexRequestPropsMapper {
	reportIndex: Array<Report>
}

export interface ReportIndexRequestExtendedPropsMapper {
	reportIndexExtended: ExtendedResponseData<Array<Report>>
}

export interface ReportStoreRequestPropsMapper {
	reportStore: Report
}

export interface ReportStoreRequestExtendedPropsMapper {
	reportStoreExtended: ExtendedResponseData<Report>
}

export interface ReportShowRequestPropsMapper {
	reportShow: Report
}

export interface ReportShowRequestExtendedPropsMapper {
	reportShowExtended: ExtendedResponseData<Report>
}

export interface ReportUpdateRequestPropsMapper {
	reportUpdate: Report
}

export interface ReportUpdateRequestExtendedPropsMapper {
	reportUpdateExtended: ExtendedResponseData<Report>
}

export interface ReportDestroyRequestPropsMapper {
	reportDestroy: void
}

export interface ReportDestroyRequestExtendedPropsMapper {
	reportDestroyExtended: ExtendedResponseData<void>
}

export interface PayoutIndexRequestPropsMapper {
	payoutIndex: Array<Payout>
}

export interface PayoutIndexRequestExtendedPropsMapper {
	payoutIndexExtended: ExtendedResponseData<Array<Payout>>
}

export interface PayoutStoreRequestPropsMapper {
	payoutStore: Payout
}

export interface PayoutStoreRequestExtendedPropsMapper {
	payoutStoreExtended: ExtendedResponseData<Payout>
}

export interface PayoutShowRequestPropsMapper {
	payoutShow: Payout
}

export interface PayoutShowRequestExtendedPropsMapper {
	payoutShowExtended: ExtendedResponseData<Payout>
}

export interface PayoutUpdateRequestPropsMapper {
	payoutUpdate: Payout
}

export interface PayoutUpdateRequestExtendedPropsMapper {
	payoutUpdateExtended: ExtendedResponseData<Payout>
}

export interface PayoutDestroyRequestPropsMapper {
	payoutDestroy: void
}

export interface PayoutDestroyRequestExtendedPropsMapper {
	payoutDestroyExtended: ExtendedResponseData<void>
}

export interface PayoutReportIndexRequestPropsMapper {
	payoutReportIndex: Array<PayoutReport>
}

export interface PayoutReportIndexRequestExtendedPropsMapper {
	payoutReportIndexExtended: ExtendedResponseData<Array<PayoutReport>>
}

export interface PayoutReportStoreRequestPropsMapper {
	payoutReportStore: PayoutReport
}

export interface PayoutReportStoreRequestExtendedPropsMapper {
	payoutReportStoreExtended: ExtendedResponseData<PayoutReport>
}

export interface PayoutReportShowRequestPropsMapper {
	payoutReportShow: PayoutReport
}

export interface PayoutReportShowRequestExtendedPropsMapper {
	payoutReportShowExtended: ExtendedResponseData<PayoutReport>
}

export interface PayoutReportUpdateRequestPropsMapper {
	payoutReportUpdate: PayoutReport
}

export interface PayoutReportUpdateRequestExtendedPropsMapper {
	payoutReportUpdateExtended: ExtendedResponseData<PayoutReport>
}

export interface PayoutReportDestroyRequestPropsMapper {
	payoutReportDestroy: void
}

export interface PayoutReportDestroyRequestExtendedPropsMapper {
	payoutReportDestroyExtended: ExtendedResponseData<void>
}

export interface ImportCsvRequestPropsMapper {
	importCsv: void
}

export interface ImportCsvRequestExtendedPropsMapper {
	importCsvExtended: ExtendedResponseData<void>
}

export interface ImportPayoutCsvRequestPropsMapper {
	importPayoutCsv: void
}

export interface ImportPayoutCsvRequestExtendedPropsMapper {
	importPayoutCsvExtended: ExtendedResponseData<void>
}

export interface ListCsvPropertiesRequestPropsMapper {
	listCsvProperties: void
}

export interface ListCsvPropertiesRequestExtendedPropsMapper {
	listCsvPropertiesExtended: ExtendedResponseData<void>
}

export interface ListPayoutCsvPropertiesRequestPropsMapper {
	listPayoutCsvProperties: void
}

export interface ListPayoutCsvPropertiesRequestExtendedPropsMapper {
	listPayoutCsvPropertiesExtended: ExtendedResponseData<void>
}

export interface ReportShowByHashRequestPropsMapper {
	reportShowByHash: Report
}

export interface ReportShowByHashRequestExtendedPropsMapper {
	reportShowByHashExtended: ExtendedResponseData<Report>
}

export interface PayoutReportShowByHashRequestPropsMapper {
	payoutReportShowByHash: PayoutReport
}

export interface PayoutReportShowByHashRequestExtendedPropsMapper {
	payoutReportShowByHashExtended: ExtendedResponseData<PayoutReport>
}

export interface LoginRequestPropsMapper {
	login: void
}

export interface LoginRequestExtendedPropsMapper {
	loginExtended: ExtendedResponseData<void>
}

export interface WhoRequestPropsMapper {
	who: void
}

export interface WhoRequestExtendedPropsMapper {
	whoExtended: ExtendedResponseData<void>
}

export interface ByeRequestPropsMapper {
	bye: void
}

export interface ByeRequestExtendedPropsMapper {
	byeExtended: ExtendedResponseData<void>
}

export interface ExportCsvRequestPropsMapper {
	exportCsv: void
}

export interface ExportCsvRequestExtendedPropsMapper {
	exportCsvExtended: ExtendedResponseData<void>
}

