import * as React from "react";
import {AbstractComponent, AjaxLoaderInjectedProps, connector} from "lumen-react-javascript";
import {
    createFormData,
    FilesInput,
    importPayoutCsvRequest,
    listPayoutCsvPropertiesRequest,
    payoutIndexRequestLoaderChunk,
    PayoutIndexRequestPropsMapper,
    payoutReportDestroyRequest,
    payoutReportIndexRequestLoaderChunk,
    PayoutReportIndexRequestPropsMapper,
    payoutReportStoreRequest,
    propertyIndexRequestLoaderChunk,
    PropertyIndexRequestPropsMapper
} from "../../../type-definitions/endpoints";
import {RouteComponentProps} from "react-router";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {Button, Jumbotron, Modal} from "react-bootstrap";
import Dropzone from 'react-dropzone'
import {renderEuros, renderMonthInput, renderYearInput} from "../../../utils";
import {monthIndex} from "../../../const";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

interface State {
    files: FilesInput
    id: string
    property_id: string
    date: Date
    type: string
    code: string
    nights: string
    guest: string
    details: string
    reference: string
    amount: string
    host_fee: string
    cleaning_fee: string
    createReport: boolean
    notes: string
    payout_date: Date
    editId: number
    mappings: object
    request: any
}

interface Props extends PayoutReportIndexRequestPropsMapper, PayoutIndexRequestPropsMapper, PropertyIndexRequestPropsMapper, AjaxLoaderInjectedProps, RouteComponentProps<{ importMonth: string, importYear: string }> {
}

export class PayoutRoute extends AbstractComponent<Props, State> {

    state = {
        files: null,
        id: "",
        property_id: "",
        date: new Date(),
        type: "",
        code: "",
        nights: "",
        guest: "",
        details: "",
        reference: "",
        amount: "",
        host_fee: "",
        cleaning_fee: "",
        createReport: false,
        notes: "",
        payout_date: new Date(),
        editId: -1,
        mappings: null,
        request: null,
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Jumbotron>
                            <h2 className="p">Import new data</h2>
                            <div className="flex-horizontal">
                                <div className="flex-1">
                                    <div className="p">
                                        {renderMonthInput(this.props.match.params.importMonth, importMonth => {
                                            this.history.push(`/payouts/${importMonth}/${this.props.match.params.importYear}`);
                                            this.props.refresh();
                                        }, true)}
                                    </div>
                                    <div className="p">
                                        {renderYearInput(this.props.match.params.importYear, importYear => {
                                            this.history.push(`/payouts/${this.props.match.params.importMonth}/${importYear}`);
                                            this.props.refresh();
                                        }, true)}
                                    </div>
                                </div>
                                <div className="flex-1">
                                    &nbsp;
                                </div>
                                <div className="flex-1">
                                    <div className="flex-vertical">
                                        <div
                                            className="p border bg-white flex-horizontal justify-content-center align-items-center">
                                            <Dropzone onDrop={files => this.setState({files})}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <span>Drop CSV here</span>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <div className="pt flex-horizontal justify-content-between">
                                            {/*<div className="flex-horizontal">*/}
                                            {/*<div*/}
                                            {/*className="cursor-pointer display-inline-block"*/}
                                            {/*onClick={() => confirm("Are you sure this CSV is reservations?") && listCsvPropertiesRequest(*/}
                                            {/*{csv: this.state.files}*/}
                                            {/*)*/}
                                            {/*.then((listings: any) => {*/}
                                            {/*let mappings = {};*/}
                                            {/*for (let i = 0; i < listings.length; i++) {*/}
                                            {/*mappings[listings[i]] = null;*/}
                                            {/*}*/}
                                            {/*this.setState({*/}
                                            {/*mappings,*/}
                                            {/*request: () => importCsvRequest(createFormData({csv: this.state.files}, {mappings: JSON.stringify(this.state.mappings)}), {*/}
                                            {/*headers: {*/}
                                            {/*"Content-Type": 'multipart/form-data'*/}
                                            {/*}*/}
                                            {/*}).then(() => this.props.refresh())*/}
                                            {/*});*/}
                                            {/*})}*/}
                                            {/*>*/}
                                            {/*<Button>Import Reservations</Button>*/}
                                            {/*</div>*/}
                                            {/*</div>*/}
                                            <div className="flex-horizontal">
                                                <div
                                                    className="cursor-pointer display-inline-block"
                                                    onClick={() => confirm("Are you sure this CSV is payouts?") && listPayoutCsvPropertiesRequest(
                                                        {csv: this.state.files}
                                                    )
                                                        .then((listings: any) => {
                                                            let mappings = {};
                                                            for (let i = 0; i < listings.length; i++) {
                                                                mappings[listings[i]] = null;
                                                            }
                                                            this.setState({
                                                                mappings,
                                                                request: () => importPayoutCsvRequest(createFormData({csv: this.state.files}, {mappings: JSON.stringify(this.state.mappings)}), {
                                                                    headers: {
                                                                        "Content-Type": 'multipart/form-data'
                                                                    }
                                                                }).then(() => this.props.refresh())
                                                            });
                                                        })}
                                                >
                                                    <Button>Import Payouts</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <Table striped bordered hover size="sm" responsive="sm">
                            <thead>
                            <tr>
                                <td>Id</td>
                                <td>Date</td>
                                <td>Payout</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.payoutIndex.map(pi => (
                                <React.Fragment key={pi.id}>
                                    <tr>
                                        <td>{pi.id}</td>
                                        <td>{pi.date}</td>
                                        <td>{renderEuros(pi.payout)}</td>
                                    </tr>
                                    {pi.entries.length > 0 && (
                                        <tr>
                                            <td colSpan={3}>
                                                <Table style={{fontSize: "80%"}} striped bordered hover size="sm"
                                                       responsive="sm">
                                                    <thead>
                                                    <tr>
                                                        <td>Id</td>
                                                        <td>Payout Date</td>
                                                        <td>Date</td>
                                                        <td>Type</td>
                                                        <td>Code</td>
                                                        <td>Nights</td>
                                                        <td>Guest</td>
                                                        <td>Details</td>
                                                        <td>Reference</td>
                                                        <td>Amount</td>
                                                        <td>Host Fee</td>
                                                        <td>Cleaning Fee</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {pi.entries.map(e => (
                                                        <tr key={e.id}>
                                                            <td>{e.id}</td>
                                                            <td>{e.payout_date}</td>
                                                            <td>{e.date}</td>
                                                            <td>{e.type}</td>
                                                            <td>{e.code}</td>
                                                            <td>{e.nights}</td>
                                                            <td>{e.guest}</td>
                                                            <td>{e.details}</td>
                                                            <td>{e.reference}</td>
                                                            <td>{renderEuros(e.amount)}</td>
                                                            <td>{renderEuros(e.host_fee)}</td>
                                                            <td>{renderEuros(e.cleaning_fee)}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col sm={4}>
                        <Table striped bordered hover size="sm" responsive="sm">
                            <thead>
                            <tr>
                                <td>Id</td>
                                <td>&nbsp;</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.payoutReportIndex.map(r => (
                                <tr key={r.id}>
                                    <td>{r.id}</td>
                                    <td>
                                        <div className="flex-horizontal">
                                            <div
                                                onClick={() => window.open(`${window.location.origin}/payouts/report/${r.hash}`, '_blank')}
                                                className="cursor-pointer ph">
                                                <FontAwesomeIcon icon="link"/>
                                            </div>
                                            <div
                                                onClick={() => confirm("Are you sure you want to delete") ? payoutReportDestroyRequest(r.id).then(() => this.props.refresh()) : null}
                                                className="cursor-pointer ph">
                                                <FontAwesomeIcon icon="trash"/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td className="text-right" colSpan={3}>
                                    <Button
                                        disabled={parseInt(this.props.match.params.importMonth) < 0 || parseInt(this.props.match.params.importYear) < 0}
                                        onClick={() => this.setState({createReport: true})}>Create Report</Button>
                                </td>
                            </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                <Modal show={this.state.createReport} onHide={() => this.setState({createReport: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="p">
                            <span>Notes</span><br/>
                            <Form.Control value={this.state.notes} as="textarea"
                                          onChange={e => this.setState({notes: e.target.value})}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({createReport: false})}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            payoutReportStoreRequest({
                                id: null,
                                month: parseInt(this.props.match.params.importMonth),
                                year: parseInt(this.props.match.params.importYear),
                                notes: this.state.notes,
                            }).then(() => {
                                this.props.refresh();
                                this.setState({createReport: false});
                            })
                        }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={!!this.state.mappings && !!this.state.request}
                    onHide={() => this.setState({mappings: null, request: null})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Set mappings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="p">
                            {this.renderMappings()}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({mappings: null, request: null})}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                            this.state.request({mappings: this.state.mappings}).then(() => {
                                this.props.refresh();
                                this.setState({mappings: null, request: null});
                            })
                        }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }

    private renderMappings() {
        let mappingsElements = [];
        for (let key in this.state.mappings) {
            mappingsElements.push(
                <div key={key} className="flex-horizontal">
                    <div className="p">{key}</div>
                    <div className="p">
                        <select value={this.state.mappings[key]}
                                onChange={e => {
                                    let mappings = {...this.state.mappings};
                                    mappings[key] = e.target.value;
                                    this.setState({mappings});
                                }}>
                            <option>Select...</option>
                            {this.props.propertyIndex.map(p =>
                                <option key={p.id} value={p.id}>{p.address}</option>
                            )}
                        </select>
                    </div>
                </div>
            );
        }
        return mappingsElements;
    }

}

export default connector(PayoutRoute, {
    ajaxLoaderOptions: {
        promises: {
            ...propertyIndexRequestLoaderChunk((props, _) => _()),
            ...payoutIndexRequestLoaderChunk((props, _) => {
                let filter = [];
                if (parseInt(props.match.params.importMonth) > 0) {
                    filter.push(['month', 'eq', props.match.params.importMonth]);
                }
                if (parseInt(props.match.params.importYear) > 0) {
                    filter.push(['year', 'eq', props.match.params.importYear]);
                }
                return _({
                    with: ['entries'],
                    filter
                })
            }),
            ...payoutReportIndexRequestLoaderChunk((props, _) => {
                let filter = [];
                if (parseInt(props.match.params.importMonth) > 0) {
                    filter.push(['month', 'eq', props.match.params.importMonth]);
                }
                if (parseInt(props.match.params.importYear) > 0) {
                    filter.push(['year', 'eq', props.match.params.importYear]);
                }
                return _({filter})
            }),
        }
    }
});
