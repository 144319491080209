import * as React from "react";
import {AbstractComponent, AjaxLoaderInjectedProps, connector} from "lumen-react-javascript";
import {
    propertyIndexRequestLoaderChunk,
    PropertyIndexRequestPropsMapper,
    propertyStoreRequest,
    propertyUpdateRequest,
    userIndexRequestLoaderChunk,
    UserIndexRequestPropsMapper
} from "../../../type-definitions/endpoints";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {RouteComponentProps} from "react-router";
import {Jumbotron} from "react-bootstrap";
import * as moment from "moment";
import {Link} from "react-router-dom";

interface Props extends UserIndexRequestPropsMapper, PropertyIndexRequestPropsMapper, AjaxLoaderInjectedProps, RouteComponentProps {
}

interface State {
    address: string;
    editId: number;
    fee_cut: number;
    is_old_tax_model: boolean;
}

export class IndexRoute extends AbstractComponent<Props, State> {

    state = {
        address: "",
        editId: -1,
        fee_cut: 0.3,
        is_old_tax_model: false,
    };

    render() {
        const m = moment();
        const month = m.format("M");
        const year = m.format("YYYY");
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Jumbotron>
                            <h2 className="p">Your properties</h2>
                            <h2 className="p"><Link to={`/payouts/${month}/${year}`}>Go to your payouts</Link></h2>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover size="sm" responsive="sm">
                            <thead>
                            <tr>
                                <td>ID</td>
                                <td>Address</td>
                                <td>Fee cut</td>
                                <td>Is old tax model?</td>
                                <td>&nbsp;</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.propertyIndex.map(pi => this.state.editId !== pi.id ? (
                                <tr key={pi.id}>
                                    <td>{pi.id}</td>
                                    <td className="text-primary cursor-pointer"
                                        onClick={() => this.props.history.push(`/property/${pi.id}/${month}/${year}`)}>{pi.address}</td>
                                    <td>{pi.fee_cut}</td>
                                    <td>{pi.is_old_tax_model ? "Yes" : "No"}</td>
                                    <td>
                                        <div className="flex-horizontal">
                                            <div
                                                onClick={() => {
                                                    this.setState({
                                                        editId: pi.id,
                                                        address: pi.address,
                                                        fee_cut: pi.fee_cut,
                                                        is_old_tax_model: pi.is_old_tax_model,
                                                    })
                                                }}
                                                className="cursor-pointer ph"
                                            >
                                                <FontAwesomeIcon icon="edit"/>
                                            </div>
                                            <div
                                                onClick={() => this.props.history.push(`/property/${pi.id}/${month}/${year}`)}
                                                className="cursor-pointer ph"
                                            >
                                                <FontAwesomeIcon icon="link"/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                this.editRow(pi.id)
                            ))}
                            </tbody>
                            <tfoot>
                            {this.state.editId < 0 ? this.editRow() : <tr>
                                <td colSpan={5}>&nbsp;</td>
                            </tr>}
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    private editRow(id = null) {
        let callback = () => {
            const payload = {
                id,
                address: this.state.address,
                fee_cut: this.state.fee_cut,
                is_old_tax_model: this.state.is_old_tax_model,
            };
            (id ? propertyUpdateRequest(payload) : propertyStoreRequest(payload)).then(() => {
                this.setState({
                    editId: -1,
                    address: "",
                    fee_cut: 0.3,
                    is_old_tax_model: false,
                });
                this.props.refresh();
            })
        };
        return (
            <tr>
                <td>{id}</td>
                <td>
                    <Form.Control value={this.state.address} type="text" placeholder="Property name"
                                  onChange={e => this.setState({address: e.target.value})}/>
                </td>
                <td>
                    <Form.Control value={this.state.fee_cut.toString()} type="text" placeholder="Fee cut"
                                  onChange={e => this.setState({fee_cut: parseFloat(e.target.value)})}/>
                </td>
                <td>
                    <input type="checkbox" checked={this.state.is_old_tax_model}
                           onChange={e => this.setState({is_old_tax_model: e.target.checked})}/>
                </td>
                <td>
                    {id ? (
                        <div className="flex-horizontal">
                            <div onClick={() => callback()} className="cursor-pointer ph">
                                <FontAwesomeIcon icon="edit"/>
                            </div>
                            <div onClick={() => this.setState({editId: -1})} className="cursor-pointer ph">
                                <FontAwesomeIcon icon="step-backward"/>
                            </div>
                        </div>
                    ) : (
                        <div onClick={() => callback()} className="cursor-pointer ph"><FontAwesomeIcon icon="plus"/>
                        </div>
                    )}
                </td>
            </tr>
        );
    }

}

export default connector(IndexRoute, {
    ajaxLoaderOptions: {
        promises: {
            ...userIndexRequestLoaderChunk((props, _) => _()),
            ...propertyIndexRequestLoaderChunk((props, _) => _()),
        }
    }
});