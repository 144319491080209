export const monthIndex = [
    {index: 1, month: "January"},
    {index: 2, month: "February"},
    {index: 3, month: "March"},
    {index: 4, month: "April"},
    {index: 5, month: "May"},
    {index: 6, month: "June"},
    {index: 7, month: "July"},
    {index: 8, month: "August"},
    {index: 9, month: "September"},
    {index: 10, month: "October"},
    {index: 11, month: "November"},
    {index: 12, month: "December"},
];

export const monthIndexWithAll = [
    {index: -1, month: "All"},
    ...monthIndex
];