import * as React from 'react';
import {boot} from 'src/index';
import {Route, Switch} from 'react-router-dom';
import * as config from "config";
import {withProps} from "recompose";
import {appComposer, AppRouter, AuthUtils} from "lumen-react-javascript";
import axios from "axios";
import IndexRoute from "public/routes";
import Container from "react-bootstrap/Container";
import PropertyRoute from "public/routes/property";
import ReportRoute from "public/routes/report";
import AuthenticatedUserContextLoader from "../../components/authenticated-user-context-loader";
import * as queryString from "query-string";
import PayoutReportRoute from "public/routes/payout-report";
import PayoutRoute from "public/routes/payout";

class Index extends React.Component {

    componentDidMount() {
        const q = queryString.parse(window.location.search);
        if (q.token) {
            AuthUtils.login(q.token, {});
            setTimeout(() => window.location.href = config.frontendUrl, 1000);
        }
    }

    render() {
        return (
            <AppRouter>
                <Container fluid>
                    <div className="m">
                        <Switch>
                            <Route path="/report/:reportHash" exact component={ReportRoute}/>
                            <Route path="/payouts/report/:reportHash" exact component={PayoutReportRoute}/>
                            <Route render={props => (
                                <AuthenticatedUserContextLoader>
                                    <Route path="/" exact component={IndexRoute} {...props}/>
                                    <Route path="/payouts/:importMonth/:importYear" exact
                                           component={PayoutRoute} {...props}/>
                                    <Route path="/property/:propertyId/:importMonth/:importYear" exact
                                           component={PropertyRoute} {...props}/>
                                </AuthenticatedUserContextLoader>
                            )}/>
                        </Switch>
                    </div>
                </Container>
            </AppRouter>
        );
    }

}

boot(Index, {
    App: appComposer(
        withProps({
            axios,
            axiosDefaultRequestConfig: {
                baseURL: config.backendUrl,
            },
        }),
    ),
    appName: 'public',
    className: 'public',
});