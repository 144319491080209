import Form from "react-bootstrap/Form";
import {monthIndex} from "./const";
import * as React from "react";

export function renderMonthInput(value, onChange, addAll = false) {
    return (
        <Form.Control as="select" value={value} onChange={e => onChange(e.target.value)}>
            {addAll && <option value={-1}>All</option>}
            {monthIndex.map(m => (
                <option key={m.index} value={m.index}>{m.month}</option>
            ))}
        </Form.Control>
    )
}

export function renderYearInput(value, onChange, addAll = false) {
    let options = [];
    for (let i = new Date().getFullYear() + 1; i > 2016; i--) {
        options.push(<option key={i} value={i}>{i}</option>);
    }
    return (
        <Form.Control as="select" value={value} onChange={e => onChange(e.target.value)}>
            {addAll && <option value={-1}>All</option>}
            {options}
        </Form.Control>
    )
}

export function parseEuros(value) {
    if (value) {
        return (parseFloat(value.replace(/[^0-9.,]/g, '').replace(',', '.')) * 100).toFixed(0);
    }
    return value;
}

export function renderEuros(value) {
    return value ? `€${(value / 100).toFixed(2)}` : '€0.00';
}